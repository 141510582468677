import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  title: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: ({ isNavbarOpen }) =>
      `calc(100% - ${isNavbarOpen ? "22.5%" : "11.5%"})`,
    height: "100%",
  },
  inputWrapper: {
    marginTop: 20,
    display: "flex",
    width: ({ isNavbarOpen }) => (isNavbarOpen ? "62%" : "55%"),
    alignItems: "center",
    justifyContent: "space-evenly",
    marginRight: ({ isNavbarOpen }) => (isNavbarOpen ? 0 : "2%"),
  },

  appBar: {
    height: "16%",
    backgroundColor: "#11131A !important",
    zIndex: 1200,
    justifyContent: "center",
    position: "fixed",
  },
  headerWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "4%",
  },
  titleWrapper: {
    width: "63%",
    marginLeft: ({ isNavbarOpen }) => (isNavbarOpen ? 0 : "2%"),
  },
  headerTitle: {
    textAlign: "start",
    fontSize: "2.2vw",
  },
  breadcumbWrapper: {
    marginBottom: "2%",
    left: "1%",
  },
});
