import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
    tabsWrapper: {
        borderBottom: "none",
        borderColor: "divider",
        position: "fixed",
        left: "28%",
        bottom: "84%",
    },
    hiddenTab: {
        display: "hidden",
        width: "5rem",
    }
});
