import { useState } from "react";
import { FaSortDown } from "react-icons/fa6";
import PlotDuration from "../PlotDuration";
import PlotQuantity from "../PlotQuantity";
import Box from "@mui/material/Box";
import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";

export default function Plot({ data }) {
  const [plotType, setPlotType] = useState("duration");
  const [showColumn, setShowColumn] = useState(false);
  const theme = useTheme();
  const classes = useStyles({ theme });
  const toggleShowColumn = () => {
    setTimeout(() => {
      setShowColumn(false);
    }, 100);
  };
  return (
    <Box
      sx={{
        mt: 10,
      }}
    >
      <div className={classes.wrapper}>
        <div
          data-testid="lot-status-tracking-analysis"
          className={classes.title}
          onClick={() => setShowColumn((prev) => !prev)}
        >
          <b>Lot Status Tracking Analysis</b>
          <FaSortDown className={classes.arrowIcon} />
        </div>

        {showColumn && (
          <div className={classes.options}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setPlotType("duration");
                // wait until the check  box is checked
                toggleShowColumn();
              }}
            >
              <input
                data-testid="show-duration"
                type="checkbox"
                checked={plotType === "duration"}
                style={{
                  accentColor: "var(--checkbox-bg-color)",
                  marginRight: "10px",
                }}
              />
              <p>Show Duration</p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setPlotType("quantity");
                // wait until the check  box is checked
                toggleShowColumn();
              }}
            >
              <input
                data-testid="show-quantity"
                type="checkbox"
                checked={plotType === "quantity"}
                style={{
                  accentColor: "var(--checkbox-bg-color)",
                  marginRight: "10px",
                }}
              />
              <p>Show Quantity</p>
            </div>
          </div>
        )}
      </div>
      <Box
        sx={{
          padding: 2,
          boxShadow: "0px 5px 14px 0px #00000033",
          borderRadius: "10px",
          backgroundColor: theme.palette.bgColor,
        }}
      >
        {plotType === "duration" ? (
          <PlotDuration
            x={data.map((lot) => lot["LotNumber"])}
            tableRowsPerPage={data}
          />
        ) : (
          <PlotQuantity
            x={data.map((lot) => lot["LotNumber"])}
            tableRowsPerPage={data}
          />
        )}
      </Box>
    </Box>
  );
}
