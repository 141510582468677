import { useEffect, useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { getDesignTokens } from "theme/theme";
import Header from "components/common/header";
import Footer from "components/common/footer";
import { useAuth0 } from "@auth0/auth0-react";
import { AppRoutes } from "Route";
import { BrowserRouter } from "react-router-dom";
import { saveAuth, removeAuth } from "./utils/cache";
import { AppProvider } from "context/appContext";
import NavBar from "components/common/navBar/index";
import "./App.scss";

function App() {
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("theme") || localStorage.setItem("theme", "light")
  );
  const [isNavBarOpen, setIsNavbarOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const theme = useMemo(
    () => createTheme(getDesignTokens(currentTheme)),
    [currentTheme]
  );

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };
  function setMode(theme) {
    localStorage.setItem("theme", theme || "light");
    const mode = localStorage.getItem("theme") || "light";
    setCurrentTheme(mode);
  }

  async function saveToken() {
    try {
      const access_token = await getAccessTokenSilently();
      const id_token = await getIdTokenClaims();
      await removeAuth();
      await saveAuth(user, access_token, id_token["__raw"]);
      setIsAuthLoaded(true);
    } catch (e) {
      console.log(e);
      console.log(error);
      removeAuth();
    }
  }

  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      saveToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  return isAuthenticated && isAuthLoaded ? (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <div
          className="App"
          style={{ backgroundColor: theme.palette.themeColor }}
        >
          <BrowserRouter>
            <Header
              isNavbarOpen={isNavBarOpen}
              handleSearchQueryChange={handleSearchQueryChange}
              searchQuery={searchQuery}
            />
            <NavBar
              setMode={setMode}
              user={user}
              onNavBarExpand={() => setIsNavbarOpen(true)}
              onNavBarCollapse={() => setIsNavbarOpen(false)}
              logout={logout}
            />
            <AppRoutes isNavbarOpen={isNavBarOpen} />
            <Footer />
          </BrowserRouter>
        </div>
      </AppProvider>
    </ThemeProvider>
  ) : (
    <> </>
  );
}

export default App;
