import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { FaArrowUp, FaArrowDown, FaPlus, FaSortDown } from "react-icons/fa6";
import { RiDeleteBin6Line, RiDownload2Fill } from "react-icons/ri";
import { IoSearch } from "react-icons/io5";
import { IoIosLink } from "react-icons/io";
import { MdFilterAltOff } from "react-icons/md";
import Plot from "components/supplyAndDemand/plot";
import { Calendar } from "react-multi-date-picker";
import dayjs from "dayjs";
import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import constants from "../../../constants/constants.json";
import { Button, Grid, useTheme } from "@mui/material";

const LotsStatusTracking = ({
  stageFilter,
  setStageFilter,
  lotStatusTableRef,
}) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const isDarkTheme = mode === "dark";

  const [showColumn, setShowColumn] = useState(false);

  const [showLotNumber] = useState(true);
  const [showDieReceipt, setShowDieReceipt] = useState(true);

  const [showReceiptBumpDuration, setShowReceiptBumpDuration] = useState(true);
  const [showBumpIn, setShowBumpIn] = useState(true);

  const [showBumpDuration, setShowBumpDuration] = useState(true);
  const [showBumpOut, setShowBumpOut] = useState(true);

  const [showBumpProbeDuration, setShowBumpProbeDuration] = useState(true);
  const [showProbeIn, setShowProbeIn] = useState(true);

  const [showProbeDuration, setShowProbeDuration] = useState(true);
  const [showProbeOut, setShowProbeOut] = useState(true);

  const [showProbeAssemblyDuration, setShowProbeAssemblyDuration] =
    useState(true);
  const [showAssemblyIn, setShowAssemblyIn] = useState(true);

  const [showAssemblyDuration, setShowAssemblyDuration] = useState(true);
  const [showAssemblyOut, setShowAssemblyOut] = useState(true);

  const [showAssemblyTestDuration, setShowAssemblyTestDuration] =
    useState(true);
  const [showTestIn, setShowTestIn] = useState(true);

  const [showTestDuration, setShowTestDuration] = useState(true);
  const [showTestOut, setShowTestOut] = useState(true);

  const [showTestShipDuration, setShowTestShipDuration] = useState(true);
  const [showShipOut, setShowShipOut] = useState(true);

  const [showBumpYield, setShowBumpYield] = useState(true);
  const [showProbeYield, setShowProbeYield] = useState(true);
  const [showAssemblyYield, setShowAssemblyYield] = useState(true);
  const [showTestYield, setShowTestYield] = useState(true);

  const [showMCMTakaD, setShowMCMTakaD] = useState(true);
  const [showMCMTaka, setShowMCMTaka] = useState(true);
  const [showTakaDRatio, setShowTakaDRatio] = useState(true);

  //Calender checkboxes
  const [diereceptcheckbox, setDiereceiptcheckbox] = useState(true);
  const [Bumpcheckbox, setBumpCheckbox] = useState(true);
  const [Probecheckbox, setProbeCheckbox] = useState(true);
  const [Assemblycheckbox, setAssemblyCheckbox] = useState(true);
  const [Testcheckbox, setTestCheckbox] = useState(true);
  const [shipcheckbox, setShipCheckbox] = useState(true);

  //Active state in calender dates
  const [selectedDateBtnStyle, setSelectedDateBtnStyle] = useState(null);

  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("");

  const [filterBy, setFilterBy] = useState("");
  const [startDate, setStartDate] = useState(new Date("Jan 01, 2021"));
  const [endDate, setEndDate] = useState(new Date());

  //Show/Hide Columns
  const [DurationCheck, setDurationCheck] = useState(true);
  const [DatesCheck, setDatesCheck] = useState(true);
  const [YieldCheck, setYieldCheck] = useState(true);

  const [openRangeCalender, setOpenRangeCalender] = useState(false);

  const [selectedDates, setSelectedDates] = useState([]);

  const [dataPerPageState, setDataPerPageState] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [lotStatusData, setLotStatusData] = useState([]);
  const [data, setData] = useState([]);
  const [apiDefaultData, setApiDefaultData] = useState([]);

  let datewidth = 1350;
  //Here 150 is the value of total margin present in duration
  let durationwidth = 450 + 150;
  let yldwidth = 945;
  let lotnowidth = 135;

  let columnRef = useRef();

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getBackgroundColor() {
    return isDarkTheme ? "#1D1F2E" : "#FFFFFF";
  }

  function getColors() {
    return isDarkTheme ? "#FFFFFF" : "#5b5d6e";
  }

  //Add custom styling to elements
  const tableContent = document.querySelectorAll(
    ".data7_content_body_diff > div > div:nth-child(1)"
  );
  tableContent.forEach((element) => {
    element.style.backgroundColor = isDarkTheme ? "#FFFFFF" : "#1D1F2E";
  });

  const calendar = document.querySelectorAll(".rmdp-wrapper");
  calendar.forEach((element) => {
    element.style.backgroundColor = getBackgroundColor();
  });

  const week = document.querySelectorAll(".rmdp-week > div:nth-child(1)");
  week.forEach((element) => {
    element.style.color = isDarkTheme ? "#FFFFFF" : "#9a0143";
  });

  const weekDay = document.querySelectorAll(".rmdp-week > .rmdp-day");
  weekDay.forEach((element) => {
    element.style.color = isDarkTheme ? "#FFFFFF" : "#000";
  });

  const day = document.querySelectorAll(".rmdp-week-day");
  day.forEach((element) => {
    element.style.color = isDarkTheme ? "#FFFFFF" : "gray";
  });

  useEffect(() => {
    React.$fetch(React.$apis.amkorLotDataView)
      .then((res) => {
        setLotStatusData(
          res.data.sort((a, b) => -descendingComparator(a, b, "LotNumber"))
        );
        setData(res.data);
        setApiDefaultData(res.data);
      })
      .catch(() => {
        console.log("Error in Fetching Amkor Lot Data");
      });
  }, []);

  const copydata = [...lotStatusData];

  const toggleSortOrder = (columnName) => {
    setSortBy(columnName);
    setSortOrder((prevOrder) => {
      if (prevOrder === "asc") {
        return "desc";
      } else if (prevOrder === "desc") {
        return "initial";
      } else {
        return "asc";
      }
    });
  };

  const getMarginRight = (showBumpYield, showDieReceipt, showBumpDuration) => {
    if (!showBumpYield && !showDieReceipt && !showBumpDuration) return "0px";
    else if (showBumpYield && !showDieReceipt && !showBumpDuration)
      return "0px";
    else if (!showBumpYield && !showDieReceipt && showBumpDuration)
      return "15px";
    else if (showBumpYield && !showDieReceipt && showBumpDuration)
      return "15px";
    else return null;
  };

  const getWidth = (showBumpYield, showDieReceipt, showBumpDuration) => {
    if (showBumpYield && showDieReceipt && showBumpDuration) return "2880px";
    else if (!showBumpYield && !showDieReceipt && !showBumpDuration)
      return `${lotnowidth}px`;
    else if (!showBumpYield && showDieReceipt && showBumpDuration)
      return `calc(2880px - ${yldwidth}px)`;
    else if (showBumpYield && !showDieReceipt && showBumpDuration)
      return `calc(3030px - ${datewidth}px)`;
    else if (showBumpYield && showDieReceipt && !showBumpDuration)
      return `calc(3030px - ${durationwidth}px)`;
    else if (showBumpYield && !showDieReceipt && !showBumpDuration)
      return `calc(3030px - ${durationwidth + datewidth}px)`;
    else if (!showBumpYield && !showDieReceipt && showBumpDuration)
      return `calc(3030px - ${yldwidth + datewidth}px)`;
    else if (!showBumpYield && showDieReceipt && !showBumpDuration)
      return `calc(3030px - ${yldwidth + durationwidth}px)`;
  };

  useEffect(() => {
    switch (stageFilter.id) {
      case 1:
        setLotStatusData(() =>
          data.filter(
            (row) =>
              row.DieReceipt !== undefined &&
              row.BumpIn === undefined &&
              row.BumpOut === undefined &&
              row.ProbeIn === undefined &&
              row.ProbeOut === undefined &&
              row.AssemblyIn === undefined &&
              row.AssemblyOut === undefined &&
              row.TestIn === undefined &&
              row.TestOut === undefined &&
              row.ShipOut === undefined
          )
        );
        break;
      case 2:
        setLotStatusData(() => {
          return data.filter((row) => {
            return (
              row.BumpIn !== undefined &&
              row.BumpOut === undefined &&
              row.ProbeIn === undefined &&
              row.ProbeOut === undefined &&
              row.AssemblyIn === undefined &&
              row.AssemblyOut === undefined &&
              row.TestIn === undefined &&
              row.TestOut === undefined &&
              row.ShipOut === undefined
            );
          });
        });
        break;
      case 3:
        setLotStatusData(() => {
          return data.filter((row) => {
            return (
              row.BumpOut !== undefined &&
              row.ProbeIn === undefined &&
              row.ProbeOut === undefined &&
              row.AssemblyIn === undefined &&
              row.AssemblyOut === undefined &&
              row.TestIn === undefined &&
              row.TestOut === undefined &&
              row.ShipOut === undefined
            );
          });
        });
        break;
      case 4:
        setLotStatusData(() => {
          return data.filter((row) => {
            return (
              row.ProbeIn !== undefined &&
              row.ProbeOut === undefined &&
              row.AssemblyIn === undefined &&
              row.AssemblyOut === undefined &&
              row.TestIn === undefined &&
              row.TestOut === undefined &&
              row.ShipOut === undefined
            );
          });
        });
        break;
      case 5:
        setLotStatusData(() => {
          return data.filter((row) => {
            return (
              row.ProbeOut !== undefined &&
              row.AssemblyIn === undefined &&
              row.AssemblyOut === undefined &&
              row.TestIn === undefined &&
              row.TestOut === undefined &&
              row.ShipOut === undefined
            );
          });
        });
        break;
      case 6:
        setLotStatusData(() => {
          return data.filter((row) => {
            return (
              row.AssemblyIn !== undefined &&
              row.AssemblyOut === undefined &&
              row.TestIn === undefined &&
              row.TestOut === undefined &&
              row.ShipOut === undefined
            );
          });
        });
        break;
      case 7:
        setLotStatusData(() => {
          return data.filter((row) => {
            return (
              row.AssemblyOut !== undefined &&
              row.TestIn === undefined &&
              row.TestOut === undefined &&
              row.ShipOut === undefined
            );
          });
        });
        break;
      case 8:
        setLotStatusData(() => {
          return data.filter((row) => {
            return (
              row.TestIn !== undefined &&
              row.TestOut === undefined &&
              row.ShipOut === undefined
            );
          });
        });
        break;
      case 9:
        setLotStatusData(() => {
          return data.filter((row) => {
            return row.TestOut !== undefined && row.ShipOut === undefined;
          });
        });
        break;
      case 10:
        setLotStatusData(() => {
          return data.filter((row) => {
            return row.ShipOut !== undefined && row.ShipOut === undefined;
          });
        });
        break;
      case 11:
        setLotStatusData(() => {
          return data.filter((row) => {
            return row.ShipOut !== undefined;
          });
        });
        break;
      case 12:
        setLotStatusData(() => {
          return data.filter((row) => {
            return row.ShipOut !== undefined;
          });
        });
        break;
      default:
        setLotStatusData(() => {
          return data.filter((row) => {
            return (
              row.LotNumber &&
              row.LotNumber.includes(filterBy.toUpperCase()) &&
              ((showDieReceipt &&
                new Date(startDate).getTime() <=
                  new Date(row.DieReceipt).getTime() &&
                new Date(endDate).getTime() >=
                  new Date(row.DieReceipt).getTime()) ||
                (Bumpcheckbox &&
                  new Date(startDate).getTime() <=
                    new Date(row.BumpIn).getTime() &&
                  new Date(endDate).getTime() >=
                    new Date(row.BumpIn).getTime()) ||
                (Bumpcheckbox &&
                  new Date(startDate).getTime() <=
                    new Date(row.BumpOut).getTime() &&
                  new Date(endDate).getTime() >=
                    new Date(row.BumpOut).getTime()) ||
                (Probecheckbox &&
                  new Date(startDate).getTime() <=
                    new Date(row.ProbeIn).getTime() &&
                  new Date(endDate).getTime() >=
                    new Date(row.ProbeIn).getTime()) ||
                (Probecheckbox &&
                  new Date(startDate).getTime() <=
                    new Date(row.ProbeOut).getTime() &&
                  new Date(endDate).getTime() >=
                    new Date(row.ProbeOut).getTime()) ||
                (Assemblycheckbox &&
                  new Date(startDate).getTime() <=
                    new Date(row.AssemblyIn).getTime() &&
                  new Date(endDate).getTime() >=
                    new Date(row.AssemblyIn).getTime()) ||
                (Assemblycheckbox &&
                  new Date(startDate).getTime() <=
                    new Date(row.AssemblyOut).getTime() &&
                  new Date(endDate).getTime() >=
                    new Date(row.AssemblyOut).getTime()) ||
                (Testcheckbox &&
                  new Date(startDate).getTime() <=
                    new Date(row.TestIn).getTime() &&
                  new Date(endDate).getTime() >=
                    new Date(row.TestIn).getTime()) ||
                (Testcheckbox &&
                  new Date(startDate).getTime() <=
                    new Date(row.TestOut).getTime() &&
                  new Date(endDate).getTime() >=
                    new Date(row.TestOut).getTime()) ||
                (shipcheckbox &&
                  new Date(startDate).getTime() <=
                    new Date(row.ShipOut).getTime() &&
                  new Date(endDate).getTime() >=
                    new Date(row.ShipOut).getTime()))
            );
          });
        });
        break;
    }
  }, [
    Assemblycheckbox,
    Bumpcheckbox,
    Probecheckbox,
    Testcheckbox,
    endDate,
    filterBy,
    shipcheckbox,
    showDieReceipt,
    stageFilter.id,
    startDate,
    data,
  ]);

  const sortData = (dataArray, columnName, sortOrder, copydata) => {
    if (columnName === "") {
      // No sorting if no column is specified
      return dataArray;
    } else if (sortOrder === "initial") {
      return dataArray;
    } else if (sortOrder === "asc") {
      return copydata.sort((a, b) => {
        const valueA = a[columnName];
        const valueB = b[columnName];

        if (typeof valueA === "number" && typeof valueB === "number") {
          return valueA - valueB;
        } else {
          return String(valueA).localeCompare(String(valueB));
        }
      });
    } else if (sortOrder === "desc") {
      return copydata.sort((a, b) => {
        const valueA = a[columnName];
        const valueB = b[columnName];

        // Check if both values are numbers
        if (typeof valueA === "number" && typeof valueB === "number") {
          // Compare numbers directly
          return valueB - valueA;
        } else {
          // Handle empty values
          if (!valueA && !valueB) {
            // If both values are empty, consider them equal
            return 0;
          } else if (!valueA) {
            // If valueA is empty, move it to the end
            return 1;
          } else if (!valueB) {
            // If valueB is empty, move it to the end
            return -1;
          } else {
            // Use localeCompare for strings
            return String(valueB).localeCompare(String(valueA));
          }
        }
      });
    }
  };

  const sortedData = sortData(lotStatusData, sortBy, sortOrder, copydata);

  // Sorting of Data Ends here

  // Filtering Starts From Here====================

  useEffect(() => {
    const startDate = dayjs("2019-01-01");
    const endDate = dayjs(); // Current date
    const allTimeRange = [startDate, endDate.endOf("day")];
    setSelectedDates(allTimeRange);
    setSelectedDateBtnStyle(11);
  }, []);

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };

  useEffect(() => {
    const formattedDates = selectedDates.map((date) =>
      date.format("YYYY-MM-DD")
    );

    if (formattedDates.length === 2) {
      setStartDate(formattedDates[0]);
      setEndDate(formattedDates[1]);
    }
  }, [selectedDates]);

  //Lot Number Filtering
  const applyFilter = (dataArray, filterValue) => {
    return dataArray.filter((item) => {
      return item.LotNumber.toLowerCase().includes(filterValue.toLowerCase());
    });
  };

  //Filtering columns
  const applyFilterByDateRange = (
    dataArray,
    startDateValue,
    endDateValue,
    diereceptcheckbox,
    Bumpcheckbox,
    Probecheckbox,
    Assemblycheckbox,
    Testcheckbox,
    shipcheckbox
  ) => {
    return dataArray.filter((item) => {
      const dieReceiptDate = new Date(item?.DieReceipt);
      const BumpInDate = new Date(item?.BumpIn);
      const BumpOutDate = new Date(item?.BumpOut);
      const ProbeInDate = new Date(item?.ProbeIn);
      const ProbeOutDate = new Date(item?.ProbeOut);
      const AssemblyInDate = new Date(item?.AssemblyIn);
      const AssemblyOutDate = new Date(item?.AssemblyOut);
      const TestInDate = new Date(item?.TestIn);
      const TestOutDate = new Date(item?.TestOut);
      const ShipOutDate = new Date(item?.ShipOut);

      const startDate = new Date(startDateValue);
      const endDate = new Date(endDateValue);

      let filterResult =
        (diereceptcheckbox &&
          dieReceiptDate >= startDate &&
          dieReceiptDate <= endDate) ||
        (Bumpcheckbox &&
          ((BumpInDate >= startDate && BumpInDate <= endDate) ||
            (BumpOutDate >= startDate && BumpOutDate <= endDate))) ||
        (Probecheckbox &&
          ((ProbeInDate >= startDate && ProbeInDate <= endDate) ||
            (ProbeOutDate >= startDate && ProbeOutDate <= endDate))) ||
        (Assemblycheckbox &&
          ((AssemblyInDate >= startDate && AssemblyInDate <= endDate) ||
            (AssemblyOutDate >= startDate && AssemblyOutDate <= endDate))) ||
        (Testcheckbox &&
          ((TestInDate >= startDate && TestInDate <= endDate) ||
            (TestOutDate >= startDate && TestOutDate <= endDate))) ||
        (shipcheckbox && ShipOutDate >= startDate && ShipOutDate <= endDate);

      return filterResult;
    });
  };

  const filteredData =
    filterBy && filterBy !== ""
      ? applyFilter(lotStatusData, filterBy)
      : applyFilterByDateRange(
          lotStatusData,
          startDate,
          endDate,
          diereceptcheckbox,
          Bumpcheckbox,
          Probecheckbox,
          Assemblycheckbox,
          Testcheckbox,
          shipcheckbox
        );

  useEffect(() => {
    if (filterBy) {
      setCurrentPage(1);
    } else if (startDate && endDate) {
      setCurrentPage(1);
    }
  }, [filterBy, startDate, endDate]);

  // Show/Hide Columns
  const DatesCheckClicked = (e) => {
    setDatesCheck((prev) => {
      if (prev) {
        setDurationCheck(false);
        setShowReceiptBumpDuration(false);
        setShowBumpDuration(false);
        setShowBumpProbeDuration(false);
        setShowProbeDuration(false);
        setShowProbeAssemblyDuration(false);
        setShowAssemblyDuration(false);
        setShowAssemblyTestDuration(false);
        setShowTestDuration(false);
        setShowTestShipDuration(false);
      }

      return !prev;
    });
    setShowDieReceipt((prev) => !prev);
    setShowBumpIn((prev) => !prev);
    setShowBumpOut((prev) => !prev);
    setShowProbeIn((prev) => !prev);
    setShowProbeOut((prev) => !prev);
    setShowAssemblyIn((prev) => !prev);
    setShowAssemblyOut((prev) => !prev);
    setShowTestIn((prev) => !prev);
    setShowTestOut((prev) => !prev);
    setShowShipOut((prev) => !prev);

    if (containerRef.current) {
      containerRef.current.scrollLeft = 0;
    }
  };

  const DurationCheckClicked = (e) => {
    if (DatesCheck) {
      setDurationCheck((prev) => !prev);
      setShowReceiptBumpDuration((prev) => !prev);
      setShowBumpDuration((prev) => !prev);
      setShowBumpProbeDuration((prev) => !prev);
      setShowProbeDuration((prev) => !prev);
      setShowProbeAssemblyDuration((prev) => !prev);
      setShowAssemblyDuration((prev) => !prev);
      setShowAssemblyTestDuration((prev) => !prev);
      setShowTestDuration((prev) => !prev);
      setShowTestShipDuration((prev) => !prev);

      if (containerRef.current) {
        containerRef.current.scrollLeft = 0;
      }
    }
  };

  const containerRef = useRef(null);

  const YieldCheckClicked = (e) => {
    setYieldCheck((prev) => !prev);
    setShowBumpYield((prev) => !prev);
    setShowProbeYield((prev) => !prev);
    setShowAssemblyYield((prev) => !prev);
    setShowTestYield((prev) => !prev);
    setShowMCMTakaD((prev) => !prev);
    setShowMCMTaka((prev) => !prev);
    setShowTakaDRatio((prev) => !prev);

    if (containerRef.current) {
      containerRef.current.scrollLeft = 0;
    }
  };

  const removeFilter = () => {
    setFilterBy("");
    setStageFilter({
      id: -1,
      qty: "",
    });
    const startDate = dayjs("2019-01-01");
    const endDate = dayjs(); // Current date
    const allTimeRange = [startDate, endDate.endOf("day")];
    setSelectedDates(allTimeRange);
    setSelectedDateBtnStyle(11);
    setStartDate("");
    setEndDate("");
    setOpenRangeCalender(false);

    setDiereceiptcheckbox(true);
    setBumpCheckbox(true);
    setProbeCheckbox(true);
    setAssemblyCheckbox(true);
    setTestCheckbox(true);
    setShipCheckbox(true);
  };

  // Dates of the Calender button
  const today = dayjs();

  const datebtnarray = [
    {
      _id: 1,
      name: "Today",
      dateHandler: () => {
        const currentday = [today.startOf("day"), today.endOf("day")];
        setSelectedDates(currentday);
        setSelectedDateBtnStyle(1);
      },
    },
    {
      _id: 2,
      name: "Yesterday",
      dateHandler: () => {
        const yesterday = [
          today.subtract(1, "day").startOf("day"),
          today.subtract(1, "day").endOf("day"),
        ];
        setSelectedDates(yesterday);
        setSelectedDateBtnStyle(2);
      },
    },
    {
      _id: 3,
      name: "This week",
      dateHandler: () => {
        const Weekvalue = [today.startOf("week"), today.endOf("day")];
        setSelectedDates(Weekvalue);
        setSelectedDateBtnStyle(3);
      },
    },
    {
      _id: 4,
      name: "Last 7 days",
      dateHandler: () => {
        const last7day = [
          today.subtract(6, "day").startOf("day"),
          today.endOf("day"),
        ];
        setSelectedDates(last7day);
        setSelectedDateBtnStyle(4);
      },
    },
    {
      _id: 5,
      name: "Last 14 days",
      dateHandler: () => {
        const last14day = [
          today.subtract(13, "day").startOf("day"),
          today.endOf("day"),
        ];
        setSelectedDates(last14day);
        setSelectedDateBtnStyle(5);
      },
    },
    {
      _id: 6,
      name: "Last 30 days",
      dateHandler: () => {
        const last30day = [
          today.subtract(29, "day").startOf("day"),
          today.endOf("day"),
        ];
        setSelectedDates(last30day);
        setSelectedDateBtnStyle(6);
      },
    },
    {
      _id: 7,
      name: "This month",
      dateHandler: () => {
        const thismonth = [today.startOf("month"), today.endOf("day")];
        setSelectedDates(thismonth);
        setSelectedDateBtnStyle(7);
      },
    },
    {
      _id: 8,
      name: "Last month",
      dateHandler: () => {
        const lastmonth = [
          today.subtract(1, "month").startOf("month"),
          today.subtract(1, "month").endOf("month"),
        ];
        setSelectedDates(lastmonth);
        setSelectedDateBtnStyle(8);
      },
    },
    {
      _id: 9,
      name: "This year",
      dateHandler: () => {
        const thisyear = [today.startOf("year"), today.endOf("day")];
        setSelectedDates(thisyear);
        setSelectedDateBtnStyle(9);
      },
    },
    {
      _id: 10,
      name: "Last year",
      dateHandler: () => {
        const lastyear = [
          today.subtract(1, "year").startOf("year"),
          today.subtract(1, "year").endOf("year"),
        ];
        setSelectedDates(lastyear);
        setSelectedDateBtnStyle(10);
      },
    },
    {
      _id: 11,
      name: "All time",
      dateHandler: () => {
        const startDate = dayjs("2019-01-01");
        const endDate = dayjs(); // Current date
        const allTimeRange = [startDate, endDate.endOf("day")];
        setSelectedDates(allTimeRange);
        setSelectedDateBtnStyle(11);
      },
    },
  ];

  // Color Code generator Code for the last Column
  function ColorGenerator(percentage) {
    var r,
      g,
      b = 0;

    if (isNaN(percentage)) {
      g = 255;
      r = 0;
    } else if (percentage < 50) {
      g = 255;
      r = Math.round(5.1 * percentage);
    } else {
      r = 255;
      g = Math.round(510 - 5.1 * percentage);
    }

    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return "#" + ("000000" + h.toString(16)).slice(-6);
  }

  const columnConfigs = [
    {
      key: "LotNumber",
      className: "data7_content_body_same",
      background: "#fff",
      show: showLotNumber,
      render: (value) => <p style={{ fontWeight: "500" }}>{value}</p>,
    },
    {
      key: "DieReceipt",
      className: "data7_content_body_same",
      background: "var(--bg-color-1)",
      show: showDieReceipt,
      render: (value) =>
        value ? (
          <p
            style={{
              color: isDarkTheme ? "#9584FF" : theme.palette.text.primary,
            }}
          >
            {value}
          </p>
        ) : (
          <p
            style={{
              color: isDarkTheme ? "#9584FF" : theme.palette.text.primary,
            }}
          >
            -
          </p>
        ),
    },
    {
      key: "ReceiptBumpDuration",
      className: "data7_content_body_diff",
      background:
        "linear-gradient(to right, var(--bg-color-1) 50%, var(--bg-color-2) 50%)",
      show: showReceiptBumpDuration,
      render: (value) => (
        <div>{value !== undefined ? `${value} days` : "days"}</div>
      ),
    },

    {
      key: "BumpIn",
      className: "data7_content_body_same",
      background: "var(--bg-color-2)",
      show: showBumpIn,
      render: (value) =>
        value ? (
          <p style={{ color: "var(--text-color-2)" }}>{value}</p>
        ) : (
          <p style={{ color: "var(--text-color-2)" }}>-</p>
        ),
    },

    {
      key: "BumpDuration",
      className: "data7_content_body_diff",
      background:
        "linear-gradient(to right, var(--bg-color-2) 50%, var(--bg-color-2) 50%)",
      show: showReceiptBumpDuration,
      render: (value) => (
        <div>{value !== undefined ? `${value} days` : "days"}</div>
      ),
    },

    {
      key: "BumpOut",
      className: "data7_content_body_same",
      background: "var(--bg-color-2)",
      show: showBumpOut,
      render: (value) =>
        value ? (
          <p style={{ color: "var(--text-color-2)" }}>{value}</p>
        ) : (
          <p style={{ color: "var(--text-color-2)" }}>-</p>
        ),
    },

    {
      key: "BumpProbeDuration",
      className: "data7_content_body_diff",
      background:
        "linear-gradient(to right, var(--bg-color-2) 50%, var(--bg-color-3) 50%)",
      show: showBumpProbeDuration,
      render: (value) => (
        <div>{value !== undefined ? `${value} days` : "days"}</div>
      ),
    },

    {
      key: "ProbeIn",
      className: "data7_content_body_same",
      background: "var(--bg-color-3)",
      show: showProbeIn,
      render: (value) =>
        value ? (
          <p style={{ color: "var(--text-color-3)" }}>{value}</p>
        ) : (
          <p style={{ color: "var(--text-color-3)" }}>-</p>
        ),
    },

    {
      key: "ProbeDuration",
      className: "data7_content_body_diff",
      background:
        "linear-gradient(to right, var(--bg-color-3) 50%, var(--bg-color-3) 50%)",
      show: showProbeDuration,
      render: (value) => (
        <div>{value !== undefined ? `${value} days` : "days"}</div>
      ),
    },

    {
      key: "ProbeOut",
      className: "data7_content_body_same",
      background: "var(--bg-color-3)",
      show: showProbeOut,
      render: (value) =>
        value ? (
          <p style={{ color: "var(--text-color-3)" }}>{value}</p>
        ) : (
          <p style={{ color: "var(--text-color-3)" }}>-</p>
        ),
    },

    {
      key: "ProbeAssemblyDuration",
      className: "data7_content_body_diff",
      background:
        "linear-gradient(to right, var(--bg-color-3) 50%, var(--bg-color-4) 50%)",
      show: showProbeAssemblyDuration,
      render: (value) => (
        <div>{value !== undefined ? `${value} days` : "days"}</div>
      ),
    },

    {
      key: "AssemblyIn",
      className: "data7_content_body_same",
      background: "var(--bg-color-4)",
      show: showAssemblyIn,
      render: (value) =>
        value ? (
          <p style={{ color: "var(--text-color-4)" }}>{value}</p>
        ) : (
          <p style={{ color: "var(--text-color-4)" }}>-</p>
        ),
    },

    {
      key: "AssemblyDuration",
      className: "data7_content_body_diff",
      background:
        "linear-gradient(to right, var(--bg-color-4) 50%, var(--bg-color-4) 50%)",
      show: showAssemblyDuration,
      render: (value) => (
        <div>{value !== undefined ? `${value} days` : "days"}</div>
      ),
    },

    {
      key: "AssemblyOut",
      className: "data7_content_body_same",
      background: "var(--bg-color-4)",
      show: showAssemblyOut,
      render: (value) =>
        value ? (
          <p style={{ color: "var(--text-color-4)" }}>{value}</p>
        ) : (
          <p style={{ color: "var(--text-color-4)" }}>-</p>
        ),
    },

    {
      key: "AssemblyTestDuration",
      className: "data7_content_body_diff",
      background:
        "linear-gradient(to right, var(--bg-color-4) 50%, var(--bg-color-5) 50%)",
      show: showAssemblyTestDuration,
      render: (value) => (
        <div>{value !== undefined ? `${value} days` : "days"}</div>
      ),
    },

    {
      key: "TestIn",
      className: "data7_content_body_same",
      background: "var(--bg-color-5)",
      show: showTestIn,
      render: (value) =>
        value ? (
          <p style={{ color: "var(--text-color-5)" }}>{value}</p>
        ) : (
          <p style={{ color: "var(--text-color-5)" }}>-</p>
        ),
    },

    {
      key: "TestDuration",
      className: "data7_content_body_diff",
      background:
        "linear-gradient(to right, var(--bg-color-5) 50%, var(--bg-color-5) 50%)",
      show: showTestDuration,
      render: (value) => (
        <div>{value !== undefined ? `${value} days` : "days"}</div>
      ),
    },

    {
      key: "TestOut",
      className: "data7_content_body_same",
      background: "var(--bg-color-5)",
      show: showTestOut,
      render: (value) =>
        value ? (
          <p style={{ color: "var(--text-color-5)" }}>{value}</p>
        ) : (
          <p style={{ color: "var(--text-color-6)" }}>-</p>
        ),
    },

    {
      key: "TestShipDuration",
      className: "data7_content_body_diff",
      background:
        "linear-gradient(to right, var(--bg-color-5) 50%, var(--bg-color-6) 50%)",
      show: showTestShipDuration,
      render: (value) => (
        <div>{value !== undefined ? `${value} days` : "days"}</div>
      ),
    },

    {
      key: "ShipOut",
      className: "data7_content_body_same",
      background: "var(--bg-color-6)",
      show: showShipOut,
      render: (value) =>
        value ? (
          <p style={{ color: "var(--text-color-6)" }}>{value}</p>
        ) : (
          <p style={{ color: "var(--text-color-6)" }}>-</p>
        ),
    },
  ];

  const columnConfigs2 = [
    {
      className: "data7_content_body_same_yield",
      background: "var(--bg-color-2)",
      color: "var(--progress-bg-color-1)",
      show: showBumpYield,
      render: (data, column) => (
        <>
          {data?.BumpYield && data?.BumpOutDie ? (
            <div>
              <div>
                <span style={{ color: column.color }}>{data.BumpYield}%</span>{" "}
                <span>{data.BumpOutDie}</span>
              </div>
              <div>
                <div
                  style={{
                    width: data.BumpYield > 100 ? "100%" : `${data.BumpYield}%`,
                    background: column.color,
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <span style={{ color: column.color }}>-</span>
            </div>
          )}
        </>
      ),
    },

    {
      className: "data7_content_body_same_yield",
      background: "var(--bg-color-3)",
      color: "var(--progress-bg-color-2)",
      show: showProbeYield,
      render: (data, column) => (
        <>
          {data?.ProbeYield && data?.ProbeOutDie ? (
            <div>
              <div>
                <span style={{ color: column.color }}>{data.ProbeYield}%</span>{" "}
                <span>{data.ProbeOutDie}</span>
              </div>
              <div>
                <div
                  style={{
                    width:
                      data.ProbeYield > 100 ? "100%" : `${data.ProbeYield}%`,
                    background: column.color,
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <span style={{ color: column.color }}>-</span>
            </div>
          )}
        </>
      ),
    },

    {
      className: "data7_content_body_same_yield",
      background: "var(--bg-color-4)",
      color: "var(--progress-bg-color-3)",
      show: showAssemblyYield,
      render: (data, column) => (
        <>
          {data?.AssemblyYield && data?.AssemblyOutDie ? (
            <div>
              <div>
                <span style={{ color: column.color }}>
                  {data.AssemblyYield}%
                </span>{" "}
                <span>{data.AssemblyOutDie}</span>
              </div>
              <div>
                <div
                  style={{
                    width:
                      data.AssemblyYield > 100
                        ? "100%"
                        : `${data.AssemblyYield}%`,
                    background: column.color,
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <span style={{ color: column.color }}>-</span>
            </div>
          )}
        </>
      ),
    },

    {
      className: "data7_content_body_same_yield",
      background: "var(--bg-color-5)",
      color: "var(--progress-bg-color-4)",
      show: showTestYield,
      render: (data, column) => (
        <>
          {data?.TestYield && data?.TestOutDie ? (
            <div>
              <div>
                <span style={{ color: column.color }}>{data.TestYield}%</span>{" "}
                <span>{data.TestOutDie}</span>
              </div>
              <div>
                <div
                  style={{
                    width: data.TestYield > 100 ? "100%" : `${data.TestYield}%`,
                    background: column.color,
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <span style={{ color: column.color }}>-</span>
            </div>
          )}
        </>
      ),
    },
  ];

  const columnConfigs3 = [
    {
      className: "data7_content_body_same_taka",
      background: isDarkTheme
        ? "rgb(29, 31, 46)"
        : "var(--mcm-taka-d-bg-color)",
      color: "red",
      show: showMCMTakaD,
      render: (data, column) => (
        <>
          {data?.TestOutDieM ? (
            <p style={{ color: column.color }}>{data.TestOutDieM}</p>
          ) : (
            <p style={{ color: column.color }}>-</p>
          )}
        </>
      ),
    },
    {
      className: "data7_content_body_same_taka",
      background: isDarkTheme ? "rgb(29, 31, 46)" : "#E0F8DE",
      color: isDarkTheme ? "#1BC39B" : "darkgreen",
      show: showMCMTaka,
      render: (data, column) => (
        <>
          {data?.TestOutDieN ? (
            <p style={{ color: column.color }}>{data.TestOutDieN}</p>
          ) : (
            <p style={{ color: column.color }}>-</p>
          )}
        </>
      ),
    },
    {
      className: "data7_content_body_same_taka",
      color: "black",
      show: showTakaDRatio,
      render: (data, column) => (
        <>
          {data?.TakaDRatio ? (
            <p
              style={{
                color: isDarkTheme
                  ? ColorGenerator(data?.TakaDRatio)
                  : column.color,
              }}
            >
              {data.TakaDRatio}
            </p>
          ) : (
            <p style={{ color: column.color }}>-</p>
          )}
        </>
      ),
    },
  ];

  const columnConfigshead = [
    {
      key: "LotNumber",
      title: "Lot No.",
      className: "data7_content_head_same",
      show: showLotNumber,
      render: (column) => (
        <div
          style={{
            minWidth:
              !showBumpYield && !showDieReceipt && !showBumpDuration && "100%",
            maxWidth:
              !showBumpYield && !showDieReceipt && !showBumpDuration && "100%",
            backgroundColor: getBackgroundColor(),
            color: getColors(),
          }}
        >
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },
    {
      key: "DieReceipt",
      title: "DieReceipt",
      className: "data7_content_head_same",
      show: showDieReceipt,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },
    {
      key: "ReceiptBumpDuration",
      className: "data7_content_head_diff",
      show: showReceiptBumpDuration,
      render: (column) => (
        <div>
          <div style={{ backgroundColor: getColors() }}></div>
          {sortBy === column.key ? (
            sortOrder === "asc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowUp />
                </span>
              </div>
            ) : sortOrder === "desc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowDown />
                </span>
              </div>
            ) : (
              sortOrder === "initial" && (
                <div>
                  <span>
                    <FaSortDown />
                  </span>
                </div>
              )
            )
          ) : (
            <div>
              <span>
                <FaSortDown />
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      key: "BumpIn",
      title: "BumpIn",
      className: "data7_content_head_same",
      show: showBumpIn,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "BumpDuration",
      className: "data7_content_head_diff",
      show: showBumpDuration,
      render: (column) => (
        <div>
          <div style={{ backgroundColor: getColors() }}></div>
          {sortBy === column.key ? (
            sortOrder === "asc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowUp />
                </span>
              </div>
            ) : sortOrder === "desc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowDown />
                </span>
              </div>
            ) : (
              sortOrder === "initial" && (
                <div>
                  <span>
                    <FaSortDown />
                  </span>
                </div>
              )
            )
          ) : (
            <div>
              <span>
                <FaSortDown />
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      key: "BumpOut",
      title: "BumpOut",
      className: "data7_content_head_same",
      show: showBumpOut,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "BumpProbeDuration",
      className: "data7_content_head_diff",
      show: showBumpProbeDuration,
      render: (column) => (
        <div>
          <div style={{ backgroundColor: getColors() }}></div>
          {sortBy === column.key ? (
            sortOrder === "asc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowUp />
                </span>
              </div>
            ) : sortOrder === "desc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowDown />
                </span>
              </div>
            ) : (
              sortOrder === "initial" && (
                <div>
                  <span>
                    <FaSortDown />
                  </span>
                </div>
              )
            )
          ) : (
            <div>
              <span>
                <FaSortDown />
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      key: "ProbeIn",
      title: "ProbeIn",
      className: "data7_content_head_same",
      show: showProbeIn,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "ProbeDuration",
      className: "data7_content_head_diff",
      show: showProbeDuration,
      render: (column) => (
        <div>
          <div style={{ backgroundColor: getColors() }}></div>
          {sortBy === column.key ? (
            sortOrder === "asc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowUp />
                </span>
              </div>
            ) : sortOrder === "desc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowDown />
                </span>
              </div>
            ) : (
              sortOrder === "initial" && (
                <div>
                  <span>
                    <FaSortDown />
                  </span>
                </div>
              )
            )
          ) : (
            <div>
              <span>
                <FaSortDown />
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      key: "ProbeOut",
      title: "ProbeOut",
      className: "data7_content_head_same",
      show: showProbeOut,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "ProbeAssemblyDuration",
      className: "data7_content_head_diff",
      show: showProbeAssemblyDuration,
      render: (column) => (
        <div>
          <div style={{ backgroundColor: getColors() }}></div>
          {sortBy === column.key ? (
            sortOrder === "asc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowUp />
                </span>
              </div>
            ) : sortOrder === "desc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowDown />
                </span>
              </div>
            ) : (
              sortOrder === "initial" && (
                <div>
                  <span>
                    <FaSortDown />
                  </span>
                </div>
              )
            )
          ) : (
            <div>
              <span>
                <FaSortDown />
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      key: "AssemblyIn",
      title: "AssemblyIn",
      className: "data7_content_head_same",
      show: showAssemblyIn,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "AssemblyDuration",
      className: "data7_content_head_diff",
      show: showAssemblyDuration,
      render: (column) => (
        <div>
          <div style={{ backgroundColor: getColors() }}></div>
          {sortBy === column.key ? (
            sortOrder === "asc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowUp />
                </span>
              </div>
            ) : sortOrder === "desc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowDown />
                </span>
              </div>
            ) : (
              sortOrder === "initial" && (
                <div>
                  <span>
                    <FaSortDown />
                  </span>
                </div>
              )
            )
          ) : (
            <div>
              <span>
                <FaSortDown />
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      key: "AssemblyOut",
      title: "AssemblyOut",
      className: "data7_content_head_same",
      show: showAssemblyOut,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "AssemblyTestDuration",
      className: "data7_content_head_diff",
      show: showAssemblyTestDuration,
      render: (column) => (
        <div>
          <div style={{ backgroundColor: getColors() }}></div>
          {sortBy === column.key ? (
            sortOrder === "asc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowUp />
                </span>
              </div>
            ) : sortOrder === "desc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowDown />
                </span>
              </div>
            ) : (
              sortOrder === "initial" && (
                <div>
                  <span>
                    <FaSortDown />
                  </span>
                </div>
              )
            )
          ) : (
            <div>
              <span>
                <FaSortDown />
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      key: "TestIn",
      title: "TestIn",
      className: "data7_content_head_same",
      show: showTestIn,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "TestDuration",
      className: "data7_content_head_diff",
      show: showTestDuration,
      render: (column) => (
        <div>
          <div style={{ backgroundColor: getColors() }}></div>
          {sortBy === column.key ? (
            sortOrder === "asc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowUp />
                </span>
              </div>
            ) : sortOrder === "desc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowDown />
                </span>
              </div>
            ) : (
              sortOrder === "initial" && (
                <div>
                  <span>
                    <FaSortDown />
                  </span>
                </div>
              )
            )
          ) : (
            <div>
              <span>
                <FaSortDown />
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      key: "TestOut",
      title: "TestOut",
      className: "data7_content_head_same",
      show: showTestOut,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "TestShipDuration",
      className: "data7_content_head_diff",
      show: showTestShipDuration,
      render: (column) => (
        <div>
          <div style={{ backgroundColor: getColors() }}></div>
          {sortBy === column.key ? (
            sortOrder === "asc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowUp />
                </span>
              </div>
            ) : sortOrder === "desc" ? (
              <div>
                <span className="data7_arrow">
                  <FaArrowDown />
                </span>
              </div>
            ) : (
              sortOrder === "initial" && (
                <div>
                  <span>
                    <FaSortDown />
                  </span>
                </div>
              )
            )
          ) : (
            <div>
              <span>
                <FaSortDown />
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      key: "ShipOut",
      title: "ShipOut",
      className: "data7_content_head_same",
      show: showShipOut,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },
  ];

  const columnConfigshead2 = [
    {
      key: "BumpYield",
      title: "BumpIn",
      className: "data7_content_head_same_yield",
      show: showBumpYield,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "ProbeYield",
      title: "ProbeIn",
      className: "data7_content_head_same_yield",
      show: showProbeYield,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "AssemblyYield",
      title: "AssemblyIn",
      className: "data7_content_head_same_yield",
      show: showAssemblyYield,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "TestYield",
      title: "TestIn",
      className: "data7_content_head_same_yield",
      show: showTestYield,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },
  ];

  const columnConfigshead3 = [
    {
      key: "TestOutDieM",
      title: "MCM Taka D",
      className: "data7_content_head_same_taka",
      show: showMCMTakaD,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "TestOutDieN",
      title: "MCM Taka",
      className: "data7_content_head_same_taka",
      show: showMCMTaka,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },

    {
      key: "TakaDRatio",
      title: "TakaD-Ratio",
      className: "data7_content_head_same_taka",
      show: showTakaDRatio,
      render: (column) => (
        <div>
          <p>{column.title}</p>
          {sortBy === column.key &&
            (sortOrder === "asc" ? (
              <span className="data7_arrow">
                <FaArrowUp />
              </span>
            ) : sortOrder === "desc" ? (
              <span className="data7_arrow">
                <FaArrowDown />
              </span>
            ) : (
              sortOrder === "initial" && <span></span>
            ))}
        </div>
      ),
    },
  ];

  // Table Column End

  // Total Data Pagination Starts Here

  const dataPerPage = dataPerPageState;

  // Calculate total number of pages
  const totalPages = Math.ceil(sortedData.length / dataPerPageState);

  // Calculate starting and ending indexes for the current page
  const startIndex = (currentPage - 1) * dataPerPage;
  const endIndex = Math.min(startIndex + dataPerPage, sortedData.length);

  // Get data for the current page
  const currentPageData = sortedData.slice(startIndex, endIndex);

  // Total Data Pagination Ends Here

  // Filter Pagiantion Starts From Here

  const handlePageChange = (_, page) => {
    setCurrentPage(page);
  };

  const onRowsPerPageChange = (e) => {
    setCurrentPage(1);
    setDataPerPageState(parseInt(e.target.value, 10));
  };

  console.table({
    currentPage,
    dataPerPage,
  });

  const copyFilteredDate = [...filteredData];

  const sortedFilteredData =
    filteredData && sortData(filteredData, sortBy, sortOrder, copyFilteredDate);

  const totalFilterPages = Math.ceil(
    sortedFilteredData.length / dataPerPageState
  );

  const filterStartIndex = (currentPage - 1) * dataPerPage;
  const filterEndIndex = Math.min(
    filterStartIndex + dataPerPageState,
    sortedFilteredData.length
  );

  // Get data for the current page
  const currentPageFilteredData = sortedFilteredData.slice(
    filterStartIndex,
    filterEndIndex
  );

  const paginationFilterLogic =
    (filterBy && filterBy !== "") ||
    (startDate && endDate && startDate !== "" && endDate !== "");

  // Filter Pagiantion Starts Ends Here

  const timeStamp = () => {
    const date = new Date(),
      formattedDate =
        [
          date.getFullYear(),
          (date.getMonth() + 1).toString().padStart(2, "0"),
          date.getDate().toString().padStart(2, "0"),
        ].join("-") +
        "T" +
        [date.toTimeString().slice(0, 8).replaceAll(":", "")].join("");
    return formattedDate;
  };

  // CSV DownLoad
  const currentpagecsvdataHandler = () => {
    // Define headers
    const headers = [
      "LotNumber",
      "DieReceipt",
      "ReceiptBumpDuration",
      "BumpIn",
      "BumpDuration",
      "BumpOut",
      "BumpProbeDuration",
      "ProbeIn",
      "ProbeDuration",
      "ProbeOut",
      "ProbeAssemblyDuration",
      "AssemblyIn",
      "AssemblyDuration",
      "AssemblyOut",
      "AssemblyTestDuration",
      "TestIn",
      "TestDuration",
      "TestOut",
      "TestShipDuration",
      "ShipOut",
      "BumpYield",
      "BumpOutDie",
      "ProbeYield",
      "ProbeOutDie",
      "AssemblyYield",
      "AssemblyOutDie",
      "TestYield",
      "TestOutDie",
      "TestOutDieM",
      "TestOutDieN",
      "TakaDRatio",
    ];

    // Convert data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      filteredData
        .map((row) => headers.map((header) => row[header]).join(","))
        .join("\n");

    // Create a virtual link element to trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `LotStatusTracking_${timeStamp()}.csv`);
    document.body.appendChild(link);

    // Trigger the download
    link.click();
  };

  const entirepagecsvdataHandler = () => {
    // Define headers
    const headers = [
      "LotNumber",
      "DieReceipt",
      "ReceiptBumpDuration",
      "BumpIn",
      "BumpDuration",
      "BumpOut",
      "BumpProbeDuration",
      "ProbeIn",
      "ProbeDuration",
      "ProbeOut",
      "ProbeAssemblyDuration",
      "AssemblyIn",
      "AssemblyDuration",
      "AssemblyOut",
      "AssemblyTestDuration",
      "TestIn",
      "TestDuration",
      "TestOut",
      "TestShipDuration",
      "ShipOut",
      "BumpYield",
      "BumpOutDie",
      "ProbeYield",
      "ProbeOutDie",
      "AssemblyYield",
      "AssemblyOutDie",
      "TestYield",
      "TestOutDie",
      "TestOutDieM",
      "TestOutDieN",
      "TakaDRatio",
    ];

    // Convert data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      apiDefaultData
        .map((row) => headers.map((header) => row[header]).join(","))
        .join("\n");

    // Create a virtual link element to trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `LotStatusTrackingAll_${timeStamp()}.csv`);
    document.body.appendChild(link);

    // Trigger the download
    link.click();
  };

  useEffect(() => {
    let handler = (e) => {
      if (!columnRef.current.contains(e.target)) {
        setShowColumn(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  let calenderRef = useRef();

  useEffect(() => {
    let calenderhandler = (e) => {
      if (!calenderRef.current.contains(e.target)) {
        setOpenRangeCalender(false);
      }
    };

    document.addEventListener("mousedown", calenderhandler);

    return () => {
      document.removeEventListener("mousedown", calenderhandler);
    };
  }, []);

  const getButtonStyles = (c) => {
    const isSelected = c._id === selectedDateBtnStyle;
    let backgroundColor;
    let color;

    if (isSelected) {
      backgroundColor = "var(--checkbox-bg-color)";
      color = "#fff";
    } else {
      backgroundColor = getBackgroundColor();
      color = getColors();
    }

    return { background: backgroundColor, color: color };
  };

  const getDateRange = (selectedDates) => {
    if (selectedDates && selectedDates.length) {
      if (selectedDates.length === 1)
        return selectedDates[0].format("YYYY-MM-DD");
      else
        return `${selectedDates[0].format(
          "YYYY-MM-DD"
        )} - ${selectedDates[1].format("YYYY-MM-DD")}`;
    }
  };

  return (
    <main className="data7_container" ref={lotStatusTableRef}>
      <Grid container className="headerContent">
        <Grid item xs={5} className="title" style={{ color: getColors() }}>
          {constants.lotsStatusTracking}
        </Grid>

        <Grid item xs={6} className={"headerBtnWrapper"}>
          <div
            className="data7_top_searchbox"
            style={{ backgroundColor: getBackgroundColor() }}
          >
            <input
              data-testid="search-input"
              type="text"
              placeholder="Search By Lot No."
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value)}
              style={{
                backgroundColor: getBackgroundColor(),
                color: getColors(),
              }}
              className="search-input"
            />

            <div>
              <IoSearch
                style={{ color: isDarkTheme ? "#FFFFFF" : "#1D1F2E" }}
              />
            </div>
          </div>

          <div
            className="data7_top_selectdatebx"
            data-testid="select-date-box"
            onClick={() => setOpenRangeCalender((prev) => !prev)}
          >
            <Button
              className={"dateBtn"}
              style={{
                backgroundColor: getBackgroundColor(),
                color: getColors(),
              }}
              data-testid="selected-date"
            >
              {getDateRange(selectedDates)}
              <FaSortDown className="downArrow" />
            </Button>

            <main
              className={`data7_top_selectdatebx_calender ${
                openRangeCalender ? "calenderActive" : "calenderInActive"
              }`}
              data-testid="select-date-box-calender-container"
              onClick={(e) => e.stopPropagation()}
              ref={calenderRef}
              style={{
                backgroundColor: getBackgroundColor(),
                color: getColors(),
              }}
            >
              <div>
                <Calendar
                  data-testid="calender1"
                  width={"5%"}
                  range
                  numberOfMonths={2}
                  value={selectedDates.map((d) => d.format("YYYY-MM-DD"))}
                  onChange={handleDateChange}
                />
              </div>

              <div>
                {selectedDates && (
                  <p>
                    {selectedDates.map((date, index) => (
                      <React.Fragment key={index}>
                        {index !== 0 && " - "}
                        {date.format("YYYY-MM-DD")}
                      </React.Fragment>
                    ))}
                  </p>
                )}
              </div>

              <div>
                <div>
                  <input
                    type="checkbox"
                    data-testid="diereceipt-checkbox"
                    checked={diereceptcheckbox}
                    onChange={(e) => setDiereceiptcheckbox((prev) => !prev)}
                    style={{
                      accentColor: diereceptcheckbox
                        ? "var(--checkbox-bg-color)"
                        : "",
                    }}
                  />
                  <p>Die Receipt</p>
                </div>

                <div>
                  <input
                    type="checkbox"
                    checked={Bumpcheckbox}
                    data-testid="bump-checkbox"
                    onChange={(e) => setBumpCheckbox((prev) => !prev)}
                    style={{
                      accentColor: Bumpcheckbox
                        ? "var(--checkbox-bg-color)"
                        : "",
                    }}
                  />
                  <p>Bump</p>
                </div>

                <div>
                  <input
                    type="checkbox"
                    checked={Probecheckbox}
                    data-testid="probe-checkbox"
                    onChange={(e) => setProbeCheckbox((prev) => !prev)}
                    style={{
                      accentColor: Probecheckbox
                        ? "var(--checkbox-bg-color)"
                        : "",
                    }}
                  />
                  <p>Probe</p>
                </div>

                <div>
                  <input
                    type="checkbox"
                    checked={Assemblycheckbox}
                    data-testid="assembly-checkbox"
                    onChange={(e) => setAssemblyCheckbox((prev) => !prev)}
                    style={{
                      accentColor: Assemblycheckbox
                        ? "var(--checkbox-bg-color)"
                        : "",
                    }}
                  />
                  <p>Assembly</p>
                </div>

                <div>
                  <input
                    type="checkbox"
                    data-testid="test-checkbox"
                    checked={Testcheckbox}
                    onChange={(e) => setTestCheckbox((prev) => !prev)}
                    style={{
                      accentColor: Testcheckbox
                        ? "var(--checkbox-bg-color)"
                        : "",
                    }}
                  />
                  <p>Test</p>
                </div>

                <div>
                  <input
                    type="checkbox"
                    data-testid="ship-checkbox"
                    checked={shipcheckbox}
                    onChange={(e) => setShipCheckbox((prev) => !prev)}
                    style={{
                      accentColor: shipcheckbox
                        ? "var(--checkbox-bg-color)"
                        : "",
                    }}
                  />
                  <p>Ship</p>
                </div>
              </div>

              <div className="data7_calender_selectdate_button_container">
                {datebtnarray.map((c) => (
                  <button
                    data-testid={"date-btn-" + c.name.split(" ").join("-")}
                    key={c._id}
                    onClick={c.dateHandler}
                    // eslint-disable-next-line eqeqeq
                    disabled={c._id == selectedDateBtnStyle}
                    style={getButtonStyles(c)}
                  >
                    {c.name}
                  </button>
                ))}

                <button
                  style={{
                    backgroundColor: getBackgroundColor(),
                    color: getColors(),
                  }}
                  onClick={() => setOpenRangeCalender(false)}
                >
                  Close
                </button>
              </div>
            </main>
          </div>

          <div className="data7_top_showhide_bx">
            <div
              data-testid="show-hide-columns"
              className="showHide"
              onClick={() => setShowColumn((prev) => !prev)}
              style={{
                backgroundColor: getBackgroundColor(),
                color: getColors(),
              }}
            >
              <p>Show/Hide Columns </p>
              <div>
                <FaSortDown />
              </div>
            </div>

            <div
              style={{
                backgroundColor: getBackgroundColor(),
                color: getColors(),
              }}
              className={`data7_top_showhide_bx_content ${
                showColumn ? "columnActive" : "columnInActive"
              }`}
              ref={columnRef}
            >
              <div>
                <input
                  type="checkbox"
                  data-testid="date-checkbox"
                  checked={DatesCheck}
                  onChange={(e) => DatesCheckClicked(e)}
                  style={{
                    accentColor: DatesCheck ? "var(--checkbox-bg-color)" : "",
                  }}
                />
                <p>Dates</p>
              </div>

              <div>
                <input
                  type="checkbox"
                  data-testid="duration-checkbox"
                  checked={DurationCheck}
                  onChange={(e) => DurationCheckClicked(e)}
                  style={{
                    accentColor: DurationCheck
                      ? "var(--checkbox-bg-color)"
                      : "",
                  }}
                />
                <p>Duration</p>
              </div>

              <div>
                <input
                  type="checkbox"
                  data-testid="yield-checkbox"
                  checked={YieldCheck}
                  onChange={(e) => YieldCheckClicked(e)}
                  style={{
                    accentColor: YieldCheck ? "var(--checkbox-bg-color)" : "",
                  }}
                />
                <p>Yield / Quantity</p>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={1} className="btnWrapper">
          <div>
            <button
              data-testid="clear-filter"
              onClick={removeFilter}
              className="remove-filter-input btn"
              style={{ fontSize: "20px" }}
              title="Clear All Filters"
            >
              <MdFilterAltOff size={17} />
            </button>
          </div>

          <div>
            <button
              data-testid="download-current-entries"
              className="dwn_crt_csv_data_btn btn"
              onClick={currentpagecsvdataHandler}
              style={{ fontSize: "20px" }}
              title="Download Current Entries"
            >
              <RiDownload2Fill size={17} />
            </button>
          </div>

          <div>
            <button
              data-testid="download-all-entries"
              className="dwn_crt_entire_data_btn btn"
              onClick={entirepagecsvdataHandler}
              style={{ fontSize: "20px" }}
              title="Download All Entries"
            >
              <RiDownload2Fill size={17} />
            </button>
          </div>
        </Grid>
      </Grid>

      <div className="data7_content_pagination_box">
        <div
          className="data7_content_box"
          style={{
            backgroundColor: getBackgroundColor(),
          }}
        >
          <div
            className="data7_content"
            ref={containerRef}
            style={{
              backgroundColor: getBackgroundColor(),
            }}
          >
            <div
              className="data7_content_head"
              style={{
                minWidth:
                  showBumpYield && !showDieReceipt && !showBumpDuration
                    ? "100%"
                    : !showBumpYield && showDieReceipt && !showBumpDuration
                    ? "100%"
                    : !showBumpYield && !showDieReceipt && showBumpDuration
                    ? "100%"
                    : !showBumpYield && !showDieReceipt && !showBumpDuration
                    ? "100%"
                    : !showBumpYield && showDieReceipt && showBumpDuration
                    ? "1935px"
                    : "2910px",
                backgroundColor: getBackgroundColor(),
                color: getColors(),
                borderBottom: `1px solid ${getColors()}`,
              }}
            >
              {columnConfigshead.map(
                (column, i) =>
                  column.show && (
                    <div
                      data-testid="columnHead"
                      className={column.className}
                      onClick={
                        column.show ? () => toggleSortOrder(column.key) : null
                      }
                      key={column.key}
                    >
                      {column.render(column)}
                    </div>
                  )
              )}

              {columnConfigshead2.map(
                (column, i) =>
                  column.show && (
                    <div
                      className={column.className}
                      onClick={
                        column.show ? () => toggleSortOrder(column.key) : null
                      }
                      key={column.key}
                      style={{
                        borderLeft: i === 0 && `3px solid ${getColors()}`,
                        borderRight: `1px solid ${getColors()}`,
                      }}
                    >
                      {column.render(column)}
                    </div>
                  )
              )}

              {columnConfigshead3.map(
                (column, i) =>
                  column.show && (
                    <div
                      className={column.className}
                      onClick={
                        column.show ? () => toggleSortOrder(column.key) : null
                      }
                      key={column.key}
                      style={{
                        borderRight:
                          i === columnConfigs3.length - 1
                            ? "none"
                            : `1px solid ${getColors()}`,
                      }}
                    >
                      {column.render(column)}
                    </div>
                  )
              )}
            </div>

            {(filterBy && filterBy !== "") ||
            (startDate && endDate && startDate !== "" && endDate !== "")
              ? currentPageFilteredData.map((data, i) => (
                  <div
                    className="data7_content_body"
                    key={i}
                    style={{
                      borderBottom:
                        currentPageData.length - 1 === i
                          ? "none"
                          : `1px solid ${getColors()}`,
                      minWidth:
                        showBumpYield && !showDieReceipt && !showBumpDuration
                          ? "100%"
                          : !showBumpYield &&
                            showDieReceipt &&
                            !showBumpDuration
                          ? "100%"
                          : !showBumpYield &&
                            !showDieReceipt &&
                            showBumpDuration
                          ? "100%"
                          : !showBumpYield &&
                            !showDieReceipt &&
                            !showBumpDuration
                          ? "100%"
                          : !showBumpYield && showDieReceipt && showBumpDuration
                          ? "1935px"
                          : "2910px",
                    }}
                  >
                    {columnConfigs.map(
                      (column, j) =>
                        column.show && (
                          <div
                            className={column.className}
                            key={j}
                            style={{
                              backgroundColor: isDarkTheme
                                ? "#1D1F2E"
                                : `${column.background}`,
                              backgroundImage:
                                !isDarkTheme && `${column.background}`,
                              color: getColors(),
                            }}
                          >
                            <div
                              style={{
                                minWidth:
                                  !showBumpYield &&
                                  !showDieReceipt &&
                                  !showBumpDuration &&
                                  "100%",
                                maxWidth:
                                  !showBumpYield &&
                                  !showDieReceipt &&
                                  !showBumpDuration &&
                                  "100%",
                              }}
                            >
                              {column.className ===
                                "data7_content_body_diff" && <div />}
                              {column.show && column.render(data[column.key])}
                            </div>
                          </div>
                        )
                    )}

                    {columnConfigs2.map(
                      (column, j) =>
                        column.show && (
                          <div
                            className={column.className}
                            style={{
                              backgroundColor: isDarkTheme
                                ? "#1D1F2E"
                                : `${column.background}`,
                              color: getColors(),
                              borderLeft: j === 0 && `3px solid ${getColors()}`,
                              borderRight: `1px solid ${getColors()}`,
                            }}
                            key={j}
                          >
                            {column.render(data, column)}
                          </div>
                        )
                    )}

                    {columnConfigs3.map(
                      (column, j) =>
                        column.show && (
                          <div
                            className={column.className}
                            style={{
                              background: isDarkTheme
                                ? "#1D1F2E"
                                : columnConfigs3.length - 1 === j
                                ? ColorGenerator(data?.TakaDRatio)
                                : column.background,
                              borderRight:
                                j === columnConfigs3.length - 1
                                  ? "none"
                                  : `1px solid ${getColors()}`,
                            }}
                            key={j}
                          >
                            {column.render(data, column)}
                          </div>
                        )
                    )}
                  </div>
                ))
              : currentPageData.map((data, i) => (
                  <div
                    className="data7_content_body"
                    key={i}
                    style={{
                      borderBottom:
                        currentPageData.length - 1 === i
                          ? "none"
                          : `1px solid ${getColors()}`,
                      width:
                        showBumpYield && showDieReceipt && showBumpDuration
                          ? "2880px"
                          : !showBumpYield &&
                            !showDieReceipt &&
                            !showBumpDuration
                          ? `${lotnowidth}px`
                          : !showBumpYield && showDieReceipt && showBumpDuration
                          ? `calc(2880px - ${yldwidth}px)`
                          : showBumpYield && !showDieReceipt && showBumpDuration
                          ? `calc(3030px - ${datewidth}px)`
                          : showBumpYield && showDieReceipt && !showBumpDuration
                          ? `calc(3030px - ${durationwidth}px)`
                          : showBumpYield &&
                            !showDieReceipt &&
                            !showBumpDuration
                          ? `calc(3030px - ${durationwidth + datewidth}px)`
                          : !showBumpYield &&
                            !showDieReceipt &&
                            showBumpDuration
                          ? `calc(3030px - ${yldwidth + datewidth}px)`
                          : !showBumpYield &&
                            showDieReceipt &&
                            !showBumpDuration &&
                            `calc(3030px - ${yldwidth + durationwidth}px)`,
                    }}
                  >
                    {columnConfigs.map(
                      (column, j) =>
                        column.show && (
                          <div
                            className={column.className}
                            key={j}
                            style={{
                              background: `${column.background}`,
                              marginRight:
                                !showBumpYield &&
                                !showDieReceipt &&
                                !showBumpDuration
                                  ? "0px"
                                  : showBumpYield &&
                                    !showDieReceipt &&
                                    !showBumpDuration
                                  ? "0px"
                                  : !showBumpYield &&
                                    !showDieReceipt &&
                                    showBumpDuration
                                  ? "15px"
                                  : showBumpYield &&
                                    !showDieReceipt &&
                                    showBumpDuration
                                  ? "15px"
                                  : null,
                            }}
                          >
                            <div
                              style={{
                                borderRight:
                                  j === 0 ? `1px solid ${getColors()}` : "none",
                              }}
                            >
                              {column.className ===
                                "data7_content_body_diff" && <div />}
                              {column.render(data[column.key])}
                            </div>
                          </div>
                        )
                    )}

                    {columnConfigs2.map(
                      (column, j) =>
                        column.show && (
                          <div
                            className={column.className}
                            style={{
                              background: `${column.background}`,
                              borderLeft: j === 0 && `3px solid ${getColors()}`,
                              borderRight: `1px solid ${getColors()}`,
                            }}
                            key={j}
                          >
                            {column.render(data, column)}
                          </div>
                        )
                    )}

                    {columnConfigs3.map(
                      (column, j) =>
                        column.show && (
                          <div
                            className={column.className}
                            style={{
                              background:
                                columnConfigs3.length - 1 === j
                                  ? ColorGenerator(data?.TakaDRatio)
                                  : column.background,
                              borderRight:
                                j === columnConfigs3.length - 1
                                  ? "none"
                                  : `1px solid ${getColors()}`,
                            }}
                            key={j}
                          >
                            {column.render(data, column)}
                          </div>
                        )
                    )}
                  </div>
                ))}
          </div>
        </div>
        <div
          className="data7_pagination"
          style={{
            backgroundColor: getBackgroundColor(),
            color: getColors(),
          }}
        >
          <div>
            <Pagination
              count={Math.ceil(
                paginationFilterLogic ? totalFilterPages : totalPages
              )}
              siblingCount={0}
              page={currentPage}
              shape="circular"
              onChange={handlePageChange}
              sx={{
                fontSize: "0.8vw !important",
                "& .MuiPaginationItem-root": {
                  fontSize: "0.8vw !important",
                },
                "& .MuiButtonBase-root-MuiPaginationItem-root": {
                  height: "2vw",
                  width: "2vw",
                  borderRadius: "50%",
                },

                "& MuiSvgIcon-root-MuiPaginationItem-icon": {
                  fontSize: "1.2vw",
                },
              }}
            />

            <TablePagination
              component="div"
              count={Math.ceil(
                paginationFilterLogic
                  ? filteredData.length
                  : lotStatusData.length
              )}
              //its a hack to hide default pagination action
              ActionsComponent={"null"}
              page={currentPage - 1}
              onPageChange={handlePageChange}
              rowsPerPage={dataPerPageState}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </div>
        </div>
      </div>
      <Plot data={currentPageFilteredData} />
    </main>
  );
};

export default LotsStatusTracking;
