// Context.js
import { createContext, useState, useContext } from "react";
import constants from "../constants/constants.json";

export const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOption, setFilterOption] = useState({
    allproductType: [],
    allLinks: [],
    advanceFilter: false,
    productType: [],
    links: [],
    filterByFavorites: false,
    clearFilters: false,
  });
  const [sortOption, setSortOption] = useState("");
  const [showAdvanceFilterOptions, setShowAdvanceFilterOptions] =
    useState(false);
  const [selectedFilters, setSelectedFilters] = useState("");

  const handleFilterChange = (e) => {
    if (!e?.target?.dataset) return;

    const { value } = e.target.dataset;

    if (value === "clearFilter") {
      setFilterOption({
        ...filterOption,
        advanceFilter: false,
        productType: [],
        links: [],
        filterByFavorites: false,
        clearFilters: true,
      });
    }

    if (value === constants.advanceFilters) {
      setFilterOption({
        ...filterOption,
        advanceFilter: false,
        filterByFavorites: false,
        clearFilters: false,
      });
      setShowAdvanceFilterOptions(true);
    } else if (value === constants.favorites) {
      setFilterOption({
        ...filterOption,
        productType: [],
        links: [],
        filterByFavorites: true,
        advanceFilter: false,
        clearFilters: false,
      });
    }
  };

  return (
    <AppContext.Provider
      value={{
        searchQuery,
        filterOption,
        sortOption,
        showAdvanceFilterOptions,
        setShowAdvanceFilterOptions,
        setSearchQuery: (e) => setSearchQuery(e.target.value),
        handleFilterChange,
        setFilterOption: (val) => setFilterOption(val),
        setSortOption: (e) => setSortOption(e.target.dataset.value),
        setSelectedFilters,
        selectedFilters,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
