import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({

  pageWrapper: {
    width: "100%",
    height: "90vh",
    backgroundColor: ({ theme }) => theme.palette.bgColor,
    boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    paddingInline: "20px",
    fontFamily: "Rubik",
    display: "grid",
    gridTemplateRows: "15vh 77vh 12vh",

    "@media (max-width:768px)": {
      height: "auto",
      gridTemplateRows: "50vh 65vh 13vh",
    },
  },
  loadingWrapper: {
    marginRight: "20%",
    height: "60vh",
  },

  detailbtn: {
    background: "#F4F4F8",
    width: "87px",
    height: "34px",
    color: "black",
    border: "2px solid #A8ABC2",
    padding: "0px 10px 0px 10px",
    borderRadius: "5px",
    cursor: "pointer"
  },

  titleWrapper: {
    width: "100%",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: {
    fontSize: "20px",
    color: ({ theme }) => theme.palette.text.primary,
    fontWeight: "bold"
  },

  downloadBtn: {
    width: "130px",
    height: "37px",
    background: "#9A0143",
    fontFamily: 'Rubik',
    fontSize: '12px',
    color: "#fff",
    '&:hover': {
      backgroundColor: "#9A0143"
    },
  },
  back: {
    float: 'right',
    width: "100px",
    height: "37px",
    background: "#5B5D6E",
    fontFamily: 'Rubik',
    fontSize: '12px',
    color: "#fff",
    '&:hover': {
      backgroundColor: "#5B5D6E"
    },
  },

  backBtn: {
    width: "100px",
    height: "37px",
    background: "#5B5D6E",
    fontFamily: 'Rubik',
    fontSize: '12px',
    color: "#fff",
    '&:hover': {
      backgroundColor: "#5B5D6E"
    },
  },

  btnGroup: {
    justifyContent: 'flex-end',
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  header: {
    color: ({ theme }) => theme.palette.text.primary,
    whiteSpace: "normal",
    lineHeight: "normal",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  detailsHeader: {
    paddingBlock: "18px",

  },

  detailsTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: ({ theme }) => theme.palette.text.primary,
    textAlign: "start",
  },

  statusWrapper: {
    marginTop: "10px",
    textAlign: "start",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },

  shipmentText: {
    color: ({ theme }) => theme.palette.text.primary,
  },

  compliantStatus: {
    textAlign: "start",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  statusYes: {
    color: "green",
    marginRight: '8px',
    marginTop: '4px'

  },

  statusNo: {
    color: "rgb(191,38,38)",
    marginRight: '8px',
    marginTop: '4px'
  },

  unknownStatus: {
    color: "rgb(170,170,10)",
    marginRight: '8px',
    marginTop: '4px'
  },

  statusValue: {
    color: ({ theme }) => theme.palette.text.primary,
    marginTop: '4px'
  },

  rows: {
    color: ({ theme }) => theme.palette.text.primary,
    textAlign: "start",
    fontSize: "12px"
  },

  cell: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    color: ({ theme }) => theme.palette.text.primary
  },

  tableWrapper: {
    border: "1px solid #5B5D6E"
  },
  tableStyle: {
    color: ({ theme }) => theme.palette.text.primary,
    fontWeight: "500",
    fontSize: "13px",
    border: "1px solid #5B5D6E",
    width: "100%",
    boxShadow: "none",
  },
  statusText: {
    width: "40px",
    height: '15px',
    color: ({ theme }) => theme.palette.text.primary,
    textAlign: 'left'
  },
  tableContainer: {
    height: "70vh",
    width: "100%"
  },
  tableWrapperGrid: {
    height: "85vh",
    width: "100%",
    overflowY: "hidden"
  },
  rowCell: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }
})

