export async function getOverviewLevel3({ React, productId }) {
    const options = Object.assign(
        {
            op_code: "get_level3_page_v01",
            product_id: "",
            function_id: 2,
            offset: 0,
            count: 100,
        },
        {
            product_id: Number(productId),
        }
    );
    const res = await React.$post(React.$apis.overviewLevel3, options);
    return res.data;
}