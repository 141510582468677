import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    wrapper: {
        backgroundColor: ({ theme }) => theme.palette.themeColor,
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    profile: {
        width: ({ isNavbarOpen }) => `calc(100% - ${isNavbarOpen ? "23%" : "12%"})`,
        margin: '9% 3% 7%',
        padding: 10,
        borderRadius: 8,
        boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 1)',
        backgroundColor: ({ theme }) => theme.palette.bgColor,
    },
    info: {
        marginTop: '7%',
        color: ({ theme }) => theme.palette.text.primary
    },
    avatar: {
        width: '88px',
        height: '88px',
        borderRadius: '50%'
    },
    code: {
        borderRadius: '5px',
        outline: '0.5px solid #fff',
        textAlign: 'left'
    },
    profileContainer: {
        marginTop: '10%'
    }
})
