/*
 * @Description: Cache operation Storage
 * @Version: 2.0
 */

import mm from "moment";

/**
 * @description: IE11 browser detection
 */
export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

/**
 * @description: Set sessionStorage
 */
export function sessionSet(name, data) {
  sessionStorage.removeItem(name);
  if (typeof data == "string") {
    sessionStorage.setItem(name, data);
  } else {
    sessionStorage.setItem(name, JSON.stringify(data));
  }
}

/**
 * @description: Get sessionStorage
 */
export function sessionGet(name) {
  let sessionData = sessionStorage.getItem(name);
  if (sessionData === "string") {
    return sessionData;
  } else {
    return JSON.parse(sessionStorage.getItem(name));
  }
}

/**
 * @description: Clear sessionStorage
 */
export function sessionRemove(name) {
  sessionStorage.removeItem(name);
}

/**
 * @description: Set localStorage
 */
export function localSet(name, data) {
  localStorage.removeItem(name);
  if (typeof data === "string") {
    localStorage.setItem(name, data);
  } else {
    localStorage.setItem(name, JSON.stringify(data));
  }
}

/**
 * @description: Get localStorage
 */
export function localGet(name) {
  let localData = localStorage.getItem(name);
  if (localData === "string") {
    return localData;
  } else {
    return JSON.parse(localStorage.getItem(name));
  }
}

/**
 * @description: Clear localStorage
 */
export function localRemove(name) {
  localStorage.removeItem(name);
}

/**
 * @description: Checks if the string is empty
 */
export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}

/**
 * @description: Verify mailbox format
 */
export function isEmail(email) {
  let reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
  return reg.test(email);
}

/**
 * @description: Verify phone format
 */
export function isPhone(phone) {
  let reg = /^1[3456789]\d{9}$/;
  return reg.test(phone);
}

/**
 * @description: String comparison
 */
export function isEquals(a, b) {
  return a === b;
}

/**
 * @description: String conversion array
 */
export function strSplitArr(str, key) {
  if (this.isEmpty(str) || this.isEmpty(key)) {
    return console.error("error params");
  }
  return str.split(key);
}

/**
 * @description: URL parameter resolution
 */
export function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

/**
 * @description: Object to verify
 */
export function isObjEmpty(obj) {
  if (typeof obj != "object") return console.error("error params");
  if (Object.keys(obj).length) return true;
  return false;
}

/**
 * @description: Turn the Set array
 */
export function unique(arr) {
  return Array.from(new Set(arr));
}

/**
 * @description: Time formatting
 */
export function dateFormat(t, k = "YYYY-MM-DD") {
  if (!t) return "";
  return mm(t).format(k).toString();
}

/**
 * @description: Time formatting in yyyy-mm-ddThhmmss
 */

export function getTimeStamp() {
  return mm().format("YYYY-MM-DDTHHmmss");
}
