import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    wrapper: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #A8ABC2",
        borderRadius: "5px",
        height: "2.2vw",
        width: "10.1vw",
        backgroundColor: ({ theme }) => theme.palette.bgColor,
        color: ({ theme }) => theme.palette.text.primary
    },
    input: {
        marginLeft: "8px",
        flex: 1,
        fontFamily: "Rubik",
        fontSize: "0.7vw",
        fontWeight: 400,
        color: ({ theme }) => theme.palette.text.primary,
        backgroundColor: ({ theme }) => theme.palette.bgColor,
    },
    icon: {
        padding: "5px",
        color: ({ theme }) => theme.palette.text.primary
    }
})