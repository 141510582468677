import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  wrapper: {},

  title: {
    border: "none",
    fontSize: "20px",
    color: ({ theme }) => theme.palette.text.primary,
    marginBottom: "20px",
    display: 'flex',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  arrowIcon: {
    marginTop: '-3px'
  },
  options: {
    backgroundColor: ({ theme }) => theme.palette.bgColor,
    color: ({ theme }) => theme.palette.text.primary,
    marginTop: "-10px",
    position: "absolute",
    left: "15%",
    padding: "10px 20px",
    borderRadius: "5px",
    boxShadow: "rgba(99, 99, 99, 0.3) 0px 2px 8px 0px",
    cursor: "pointer",
    zIndex: "100",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gridGap: "15px",
    gap: "15px",
    width: '12%'
  }
});
