import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  label: {
    color: "#5b5d6e !important",
    fontFamily: "Rubik !important",
    fontSize:"0.8vw",
    fontWeight: 400,
    fontStyle: "normal",
    height: "3.9vh",
    marginTop:"-0.9vh",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    
   
    "@media (min-width: 2561px)": {
      marginTop: "-0.1vw",
    },
  },
  form: {
    width: "10vw",
    display:"flex",
    justifyContent :"center",
  },
});
