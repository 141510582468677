import { localSet, localRemove } from './tools';
import { cookieDomain } from "config/settings";
import { authCookie } from "./auths";
import Cookies from "js-cookie";

export const saveAuth = (user, access_token, id_token) => {
  return new Promise((resolve, reject) => {
    if (!user || !access_token || !id_token) return reject(false);
    localSet('user', user);
    localSet('jwtToken', access_token);
    localSet('jwt_ID_Token', id_token);
    authCookie({ name: 'authorizationcookie', value: `Bearer ${access_token}`, domain: cookieDomain });
    return resolve(true);
  })
}

export const removeAuth = () => {
    localRemove('user');
    localRemove('jwtToken');
    localRemove('jwt_ID_Token');
    Cookies.remove('authorizationcookie')
    return Promise.resolve(true);
}
