import { createUseStyles } from 'react-jss';
export const useStyles =
    createUseStyles({
        toggle: {
            cursor: 'pointer',
            display: 'flex !important'
        },
        buttonWrapper :{
            border: "none",
            padding: 0,
            backgroundColor: "transparent",
            cursor: "pointer",
        },
        expand: {
            cursor: 'pointer',
            width: '1.20vw',
            height: '1.20vw',
            color: '#a8abc2',
            padding: '4px',
        },
        drawerContainer: {
            height: '80% !important',
            borderRadius: '10px',
            margin: '20px 8px 8px 40px',
            boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 0.2) !important',
        },
        container: {
            padding: '3px'
        },
        actionContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        divider: {
            margin: '10px'
        },
        logo: {
            padding: '1vh 2vh', 
            cursor: 'pointer',
            height: ({ isExpanded }) => isExpanded ? '4.10vw' : '3.5vw',
            width: ({ isExpanded }) => isExpanded ? '11.70vw' : '7vw',
           },
        drawerContent: {
            display: 'flex',
            justifyContent: 'first baseline'
        },
        listItemText: {
            fontSize: '0.7vw !important',
            fontWeight: `${700} !important`,
            textAlign: 'left !important',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%'
        },
        text: {
            marginLeft: '5px',
            width: '100%',
        },
        version: {
            fontSize: ({ isExpanded }) => isExpanded ? "0.8vw" : "0.7vw",
            color: ({ theme }) => theme.palette.text.primary,
        },
        margin: {
             bottom: 6,  
            textAlign: 'center',
             position: 'absolute',
            left: ({ isExpanded }) => isExpanded ? "4vw" : "1.25vw",
        },
        bar: {
            color: ({ theme }) => theme.palette.divider.primary,
            borderBottomWidth: 2,
        },
        listIcon: {
            minWidth: 0,
            marginRight: ({ isExpanded }) => isExpanded ? 1 : "auto",
            justifyContent: "center",
            color: ({ theme }) => theme.palette.icon?.primary,
        },
        profile: {
            padding: '16px 20px 0 20px',
            fontSize: ({ isExpanded }) => isExpanded ? "0.8vw" : "0.6vw",
            alignSelf: ({ isExpanded }) => isExpanded ? "baseline" : "center",
            color: ({ theme }) => theme.palette.text.primary,
        },
        listItemBtn: {
            minHeight: "3vw",
            justifyContent: ({ isExpanded }) => isExpanded ? "initial" : "center",
            padding: '0 16px',
            "&:hover": {
                backgroundColor: ({ theme }) => theme.palette.bgColor,
                boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.5)",
                borderRadius: "5px",
            },
        },
        listItemIcon: {
            minWidth: 0,
            marginRight: ({ isExpanded }) => isExpanded ? 1 : "auto",
            justifyContent: "center",
            color: ({ theme }) => theme.palette.icon?.primary,
        }
        
    })
