import { Box, Tabs, Tab } from "@mui/material";
import { useMatch, useNavigate } from "react-router-dom";
import { useStyles } from "./styles";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TakaImage from "components/supplyAndDemand/takaImg";

const CustomTabs = ({ isNavbarOpen, product }) => {
  const navigate = useNavigate();
  const classes = useStyles({ isNavbarOpen });

  const match = useMatch("/overview/entry/:pid/:fid/:tid?/*");
  let { pid, fid } = match?.params || 2;

  const [value, setValue] = React.useState(Number(fid));

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    navigate(`/overview/entry/${pid}/${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    setValue(Number(fid));
  }, [fid]);

  return (
    <Box data-testid="custom-tabs">
      <TakaImage
        data-testid="taka-image"
        position="fixed"
        alt={product?.product_name ? product.product_name : "img"}
        src={product?.pic_path}
        tab={value}
      />
      <Box className={classes.tabsWrapper}>
        <Tabs value={value} onChange={handleChange} aria-label="Tabs" centered>
          <Tab className={classes.hiddenTab} {...a11yProps(0)} disabled />
          <Tab
            value={1}
            data-testid="supply-and-demand-tab"
            sx={{
              mx: 2,
              width: "15rem",
              background: "#5B5D6E",
              borderRadius: "6px 6px 0 0",
              fontSize: "15px",
              fontWeight: "700",
              textTransform: "none",
              ...(1 === value
                ? {
                    background: "#EDF1F5",
                    color: "#5B5D6E !important",
                  }
                : { color: "#ffff !important" }),
            }}
            label={"Supply & Demand"}
            style={{
              display: product?.link_name.some((p) => p.function_id === 1)
                ? "block"
                : "none",
            }}
            {...a11yProps(1)}
          />
          <Tab
            value={2}
            data-testid="parametric-analysis-tab"
            sx={{
              mx: 2,
              width: "15rem",
              background: "#5B5D6E",
              borderRadius: "6px 6px 0 0",
              fontWeight: "700",
              fontSize: "15px",
              textTransform: "none",
              ...(2 === value
                ? {
                    background: "#EDF1F5",
                    color: "#5B5D6E !important",
                  }
                : { color: "#ffff !important" }),
            }}
            label="Parametric Analysis"
            style={{
              display: product?.link_name.some((p) => p.function_id === 2)
                ? "block"
                : "none",
            }}
            {...a11yProps(2)}
          />
          <Tab
            data-testid="helvetia-compliancy-check-tab"
            value={5}
            sx={{
              mx: 2,
              width: "15rem",
              background: "#5B5D6E",
              borderRadius: "6px 6px 0 0",
              fontWeight: "700",
              fontSize: "15px",
              textTransform: "none",
              ...(5 === value
                ? {
                    background: "#EDF1F5",
                    color: "#5B5D6E !important",
                  }
                : { color: "#ffff !important" }),
            }}
            label="Helvetia Compliancy Check"
            style={{
              display: product?.link_name.some((p) => p.function_id === 5)
                ? "block"
                : "none",
            }}
            {...a11yProps(5)}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

CustomTabs.propTypes = {
  isNavbarOpen: PropTypes.bool,
  product: PropTypes.object,
};

export default CustomTabs;
