import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Stack } from "@mui/material";
import { useAppContext } from "context/appContext";
import constants from "../../constants/constants.json";

export default function AdvanceFilterModal({ handleClose, isOpen }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const {
    filterOption,
    setFilterOption,
    setShowAdvanceFilterOptions,
    setSelectedFilters,
  } = useAppContext();
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedProductLinks, setSelectedProductLinks] = useState([]);
  const { allproductType, allLinks } = filterOption;

  const handleProductTypeChange = (e) => {
    const { name, checked } = e.target;
    if (name && checked) {
      setSelectedProductTypes([...new Set([...selectedProductTypes, name])]);

      setSelectedFilters([...new Set([...selectedProductTypes, name])]);
    } else {
      setSelectedProductTypes([
        ...new Set([...selectedProductTypes.filter((p) => p !== name)]),
      ]);
      setSelectedFilters([
        ...new Set([...selectedProductTypes.filter((p) => p !== name)]),
      ]);
    }
  };

  useEffect(() => {
    if (filterOption?.clearFilters) {
      setSelectedProductLinks([]);
      setSelectedProductTypes([]);
      setSelectedFilters("");
    }
  }, [
    filterOption,
    setSelectedProductLinks,
    setSelectedProductTypes,
    setSelectedFilters,
  ]);

  const handleProductLinkChange = (e) => {
    const { name, checked } = e.target;

    if (name && checked) {
      setSelectedProductLinks([...new Set([...selectedProductLinks, name])]);
      setSelectedFilters(
        selectedProductTypes +
          "," +
          [...new Set([...selectedProductLinks, name])]
      );
    } else {
      setSelectedProductLinks([
        ...new Set([...selectedProductLinks.filter((l) => l !== name)]),
      ]);
      setSelectedFilters(
        selectedProductTypes +
          "," +
          [...new Set([...selectedProductLinks.filter((p) => p !== name)])]
      );
    }
  };

  const handleClearFilters = (e) => {
    if (
      selectedProductTypes.length !== 0 ||
      selectedProductLinks.length !== 0
    ) {
      setSelectedProductLinks([]);
      setSelectedProductTypes([]);
      setSelectedFilters("");
    }
    setFilterOption({
      ...filterOption,
      advanceFilter: false,
      filterByFavorites: false,
      productType: [],
      links: [],
      clearFilters: true,
    });

    setShowAdvanceFilterOptions(false);
  };

  const handleApplyFilter = () => {
    if (
      selectedProductTypes.length !== 0 ||
      selectedProductLinks.length !== 0
    ) {
      setFilterOption({
        ...filterOption,
        advanceFilter: true,
        filterByFavorites: false,
        productType: selectedProductTypes,
        links: selectedProductLinks,
        clearFilters: false,
      });
    }

    setShowAdvanceFilterOptions(false);
  };

  return (
    <div data-testid="AdvanceFilterModal">
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby={constants.advanceFilters}
        aria-describedby=""
      >
        <Box className={classes.wrapper} sx={{ boxShadow: 24 }}>
          <Box>
            <Typography variant="subtitle1" className={classes.text}>
              {constants.productTypes}
            </Typography>
            <Stack direction="row" justifyContent={"space-between"}>
              {allproductType?.length > 0 &&
                allproductType.map((p) => (
                  <FormGroup key={p} data-testid="productType">
                    <FormControlLabel
                      control={<Checkbox className={classes.checkbox} />}
                      label={
                        <Typography className={classes.options}>{p}</Typography>
                      }
                      checked={selectedProductTypes.includes(p)}
                      name={p}
                      onChange={handleProductTypeChange}
                    />
                  </FormGroup>
                ))}
            </Stack>
          </Box>
          <Box>
            <Typography variant="subtitle1" className={classes.title}>
              {constants.ProductDrilldown}
            </Typography>
            <Stack direction="row" justifyContent={"space-between"}>
              {allLinks?.length > 0 &&
                allLinks.map((p) => (
                  <FormGroup key={p} data-testid="links">
                    <FormControlLabel
                      control={<Checkbox className={classes.checkbox} />}
                      label={
                        <Typography className={classes.options}>{p}</Typography>
                      }
                      checked={selectedProductLinks.includes(p)}
                      name={p}
                      onChange={handleProductLinkChange}
                    />
                  </FormGroup>
                ))}
            </Stack>
          </Box>
          <Stack
            direction={"row"}
            flex
            justifyContent={"center"}
            sx={{ mt: 2 }}
          >
            <Button
              data-testid="AdvanceFilterCloseButton"
              onClick={() => setShowAdvanceFilterOptions(false)}
              className={classes.btn}
            >
              {constants.close}
            </Button>
            <Button
              data-testid="clearFilter"
              onClick={handleClearFilters}
              className={classes.btn}
            >
              {constants.clearFilters}
            </Button>
            <Button
              data-testid="applyFilter"
              onClick={handleApplyFilter}
              className={classes.btn}
            >
              {constants.apply}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
