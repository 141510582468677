import { Box } from "@mui/material";
const TakaImage = ({ src, tab, alt }) => {
  if (tab !== 1) return null;
  return (
    <Box position={"absolute"} bottom={"84.5%"} left={"22%"} top={"75%"}>
      <img src={src} alt={alt} width={"120px"} height={"120px"} />
    </Box>
  );
};

export default TakaImage;
