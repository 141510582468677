export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          bgColor: "#FFFFFF",
          themeColor: "#FFFFFF",
          fontFamily: "inherit",
          text: {
            primary: "#5b5d6e",
            secondary: "#000",
          },
          icon: {
            primary: "#B80351",
          },
          // divider: {
          //   primary: "#E6E7F0",
          //   color: "#E6E7F0",
          // },
          footer: {
            color: "#E6E7F0",
          },
          button: {
            primary: "#F4F4F8",
          },
        }
      : {
          bgColor: "#1D1F2E",
          themeColor: "#11131A",
          fontFamily: "inherit",
          text: {
            primary: "#FFFFFF",
            secondary: "#fff",
          },
          icon: {
            primary: "#FFFFFF",
          },
          // divider: {
          //   primary: "#FFFFFF",
          // },
          footer: {
            color: "#1D1F2E",
          },
          button: {
            primary: "#5B5D6E",
          },
        }),
  },
  typography: {
    subtitle2: {
      fontSize: "12px",
      fontFamily: "inherit",
      fontWeight: 700,
      color: "#5B5D6E",
    },
    subtitle1: {
      fontSize: "16px",
      fontFamily: "inherit",
      fontWeight: 600,
      color: "#5B5D6E",
      lineHeight: "200%",
    },
    h4: {
      fontWeight: 700,
      color: "#FCFCFE",
      fontFamily: "inherit",
      fontSize: "35px",
      lineHeight: "140%",
      fontStyle: "normal",
    },
    body1: {
      color: "#1D1F2E",
      textAlign: "center",
      fontFamily: "inherit",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
    },
  },
});
