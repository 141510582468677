import { Route, Routes } from "react-router-dom";
import HomePage from "pages/homePage";
import UserProfile from "pages/userProfile";
import SupplyAndDemand from "pages/supplyDemand";
import { Navigate } from "react-router-dom";

export const AppRoutes = ({ isNavbarOpen }) => {
  return (
    <Routes>
      <Route path={"/"} element={<HomePage isNavbarOpen={isNavbarOpen} />} />
      <Route
        path={"/profile"}
        element={<UserProfile isNavbarOpen={isNavbarOpen} />}
      />
      <Route
        path="/overview/entry/:pid/:fid?/:tid?/*"
        element={<SupplyAndDemand isNavbarOpen={isNavbarOpen} />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
