export default function loadAuth(loadData) {
  let jwtToken = localStorage.jwtToken;

  if (jwtToken) return loadData();

  let timer = setTimeout(() => {
    loadData();
    clearTimeout(timer);
  })
}
