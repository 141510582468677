import Highlight from "../highlight";
import { useAuth0 } from "@auth0/auth0-react";
import { dateFormat } from "utils/tools";
import { useStyles } from "./style";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import constants from "../../constants/constants.json";

const UserProfile = ({ isNavbarOpen }) => {
  const { user } = useAuth0();
  const theme = useTheme();
  const classes = useStyles({ theme, isNavbarOpen });

  return (
    <Box className={classes.wrapper} data-testid={"user-profile"}>
      <Grid container className={classes.profile}>
        <Grid item xs={4} className={classes.profileContainer}>
          <img className={classes.avatar} src={user.picture} alt={user.name} />

          <Grid item className={classes.info}>
            <h2 className="name">
              <strong>{constants.givenName}:</strong> {user.given_name}
            </h2>
            <h2 className="name">
              <strong>{constants.familyName}:</strong> {user.family_name}
            </h2>
            <h2 className="nickname">
              <strong>{constants.nickName}:</strong> {user.nickname}
            </h2>
            <h2 className="email">
              <strong>{constants.email}:</strong> {user.email}
            </h2>
            <h2 className="time">
              <strong>{constants.dateTime}:</strong>{" "}
              {dateFormat(
                user.updated_at,
                "YYYY-MM-DD h:mm:ss a"
              ).toUpperCase()}
            </h2>
          </Grid>
        </Grid>
        <Grid item xs={8} className={classes.code}>
          <Highlight>{JSON.stringify(user, null, 4)}</Highlight>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserProfile;
