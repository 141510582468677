import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import loadAuth from "utils/loadAuth";
import { useStyles } from "./style";
import { useTheme } from "@emotion/react";
import PropsTypes from "prop-types";
import TakaOverviewTable from "./takaTable";
import LotsStatusTracking from "pages/supplyDemand/lotsStatusTracking";
import { fetchSupplyDemandData } from "pages/supplyDemand/supplyDemand.service";
import { useParams } from "react-router";
import LoadingSpinner from "components/loadingSpinner";
import constants from "../../constants/constants.json";

export default function OverviewTable({ product }) {
  const lotStatusTableRef = React.useRef();
  const { pid } = useParams();
  const [stageFilter, setStageFilter] = React.useState({
    id: -1,
    qty: "",
  });
  const [tableList, setTableList] = React.useState();
  const [supplier, setSupplier] = React.useState([]);

  const onSetStageFilter = () => {
    lotStatusTableRef.current.scrollIntoView({ behavior: "smooth" });
  };

  function generateRandomId() {
    // Generate a random array of 9 bytes and convert it to a hex string
    return window.crypto.randomUUID();
  }

  function getValue(rowKey, value) {
    if (rowKey === "ADV Item Number") {
      return value || "?";
    }
    if (rowKey === "Manufacturing site") {
      return value || "";
    }
    return value || "-";
  }

  function getClassName(rowKey) {
    if (rowKey === "Manufacturing step") {
      return classes.tableHead;
    }
    return classes.text;
  }

  function getVariant(rowKey) {
    if (rowKey === "Manufacturing step") {
      return "head";
    }
    return "body";
  }

  function generateTableRows(tableList, classes) {
    const rows = [
      "Manufacturing step",
      "Manufacturing site",
      "Unit of measure",
      "ADV Item Number",
      "Units",
      "Value (Single unit)",
      "Value (Total)",
    ];

    return rows.map((rowKey) => (
      <TableRow key={rowKey}>
        <TableCell
          className={getClassName(rowKey)}
          component="th"
          scope="row"
          sx={
            rowKey === "Value (Total)"
              ? { borderBottom: "none !important" }
              : {}
          }
          style={
            rowKey === "Manufacturing step"
              ? { minWidth: 155, paddingLeft: "8px" }
              : {}
          }
          variant={getVariant()}
        >
          {rowKey}
        </TableCell>
        {tableList?.[rowKey]?.map((m, i) => (
          <TableCell
            key={generateRandomId()}
            className={getClassName(rowKey)}
            sx={{
              ...(i === tableList[rowKey].length - 1
                ? { borderRight: "none !important" }
                : {}),
              ...(rowKey === "Value (Total)"
                ? { borderBottom: "none !important" }
                : {}),
            }}
            style={
              rowKey === "Manufacturing step"
                ? { minWidth: 155, paddingLeft: "8px" }
                : {}
            }
            variant={getVariant(rowKey)}
          >
            {getValue(rowKey, m)}
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  const loadData = React.useCallback(async () => {
    try {
      const data = await fetchSupplyDemandData({
        React,
        productId: pid,
      });

      console.log("data =>", data);
      if (data) {
        setTableList(data.values);
      }
    } catch (error) {
      console.error(error);
    }
  }, [pid]);

  const theme = useTheme();
  const classes = useStyles({ theme });

  const createData = React.useCallback(() => {
    setSupplier(tableList?.Supplier?.filter((s) => s));
  }, [tableList]);

  React.useEffect(() => {
    if (!pid) {
      return;
    }

    loadAuth(loadData);
  }, [loadData, pid]);

  React.useEffect(() => {
    if (tableList) {
      createData();
    }
    return () => {};
  }, [createData, tableList]);

  if (!tableList) {
    return (
      <Grid className={classes.loadingWrapper}>
        <LoadingSpinner loadingMessage={constants.loading} />
      </Grid>
    );
  }

  return product?.product_name === "Taka" ? (
    <>
      <TakaOverviewTable
        data-testid="TakaOverviewTable"
        tableList={tableList}
        setStageFilter={setStageFilter}
        stageFilter={stageFilter}
        onSetStageFilter={onSetStageFilter}
      />
      <LotsStatusTracking
        lotStatusTableRef={lotStatusTableRef}
        stageFilter={stageFilter}
        setStageFilter={setStageFilter}
      />
    </>
  ) : (
    <TableContainer
      data-testid="overviewTable"
      component={Paper}
      className={classes.tablebContainer}
    >
      <Table sx={{ width: "100%" }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead1} variant="head">
              SUPPLIER
            </TableCell>
            {supplier.length > 0 &&
              supplier.map((s, i) => (
                <TableCell
                  className={classes.tableHead1}
                  key={i}
                  id={s}
                  sx={{
                    ...(i === supplier.length - 1
                      ? {
                          borderRight: "none !important",
                        }
                      : null),
                  }}
                  variant="head"
                >
                  {s.toUpperCase()}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>{generateTableRows(tableList, classes)}</TableBody>
      </Table>
    </TableContainer>
  );
}

OverviewTable.propTypes = {
  product: PropsTypes.any,
};
