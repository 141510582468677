import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  pageContainer: {
    backgroundColor: ({ theme }) => theme.palette.themeColor,
    height: '90vh'
  },
  pageWrapper: {
    position: "relative",
    zIndex: "1234",
    height: '67vh',
    paddingBottom: "1%",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    margin: "10% 3% 0",
    width: ({ isNavbarOpen }) => `calc(100% - ${isNavbarOpen ? "23%" : "10%"})`,
  },

  tableText: {
    color: ({ theme }) => theme.palette.text.primary,
  },
});
