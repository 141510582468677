import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  card: {
    borderRadius: "3%",
    width: "95%",
    height: "30vh",
    boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.2) !important",
    backgroundColor: ({ theme }) => theme.palette.bgColor,
  },
  cardContent: {
    padding: 0,
    marginTop: "5%",
  },
  wrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  box: {
    border: "solid 2px #A8ABC2",
    padding: 0,
    borderRadius: "5px",
    marginTop: "5px",
    marginRight: 2,
    marginBottom: "5px",
  },
  name: {
    textAlign: "start",
    fontWeight: 700,
    fontSize: "1vw",
    color: ({ theme }) => theme.palette.text.primary,
    fontFamily: "Rubik",
    marginBottom: "5px",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  type: {
    textAlign: "start",
    fontWeight: 500,
    fontSize: "0.8vw",
    color: ({ theme }) => theme.palette.text.primary,
    fontFamily: "Rubik",
    lineHeight: "15px",
  },
  break: {
    margin: "0 16px 12px 16px",
    color: "#A8ABC2",
    borderWidth: "1.5px",
  },
  link: {
    fontWeight: "600px",
    fontSize: "0.7vw",
    height: "2vw",
    fontFamily: "Rubik",
    textAlign: "center",
    color: "#FFFFFF",
    backgroundColor: "#B80351",
    "&:hover": {
      backgroundColor: "#B80351",
    },
  },
  cardsWrapper: {
    marginTop: "8%",
    backgroundColor: ({ theme }) => theme.palette.themeColor,
  },
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  productWrapper: {
    zIndex: "1234",
    height: `calc(100vh - 27vh)`,
    paddingBottom: "1%",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    margin: "-2% 3% 0",
    width: ({ isNavbarOpen }) => `calc(100% - ${isNavbarOpen ? "23%" : "12%"})`,
  },
  pageText: {
    fontSize: "0.8vw",
    fontWeight: 400,
    lineHeight: "13px",
    marginRight: "7%",
    width: "15%",
    color: ({ theme }) => theme.palette.text.primary,
  },
  grid: {
    padding: "0 16px 0 16px",
    marginBottom: 0,
    height: "10vh",
  },
  content: {
    backgroundColor: "#FFFFFF",
    width: "80%",
    height: "3vw",
    paddingRight: "5px",
  },
  productImg: {
    height: "100%",
    width: "100%",
  },
  nameWrapper: {
    paddingLeft: "10%",
  },

  pagination: {
    display: "flex",
    justifyContent: "space-between",
    width: ({ isNavbarOpen, count }) =>
      `calc(100% - ${(isNavbarOpen ? 53 : 47) - count * 0.5}%)`,
  },
});
